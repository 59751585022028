import { useQueries } from '@tanstack/react-query';
import { Answer } from '@/api/answers/types';
import { Question } from '@/api/questions/types';
import { TileGroupSelected, TileSelected } from '@/api/tile-groups/types';
import {
	useFastBrainAnswersOptions,
	useFastBrainQuestionsOptions,
	useFastBrainTileGroupsSelectedTilesOptions,
} from '@/queries/fastBrainQueries';
import { queryClient } from '@/queries';

interface UseFastBrainQuestionsDataProps {
	tileGroupSelectedTilesInitialData: TileGroupSelected[];
	questionsInitialData: Question[];
	answersInitialData: Answer[];
}

interface UseFastBrainQuestionsData {
	selectedTiles: TileSelected[];
	questions: Question[];
	answers: Answer[];
	invalidateAnswers: () => Promise<void>;
}

export const useFastBrainQuestionsData = ({
	tileGroupSelectedTilesInitialData,
	questionsInitialData,
	answersInitialData,
}: UseFastBrainQuestionsDataProps): UseFastBrainQuestionsData => {
	const { queryKey: answersKey } = useFastBrainAnswersOptions;
	const [{ data: tileGroupSelectedTiles }, { data: questions }, { data: answers }] = useQueries({
		queries: [
			{
				...useFastBrainTileGroupsSelectedTilesOptions,
				initialData: tileGroupSelectedTilesInitialData,
				staleTime: Infinity,
			},
			{
				...useFastBrainQuestionsOptions,
				initialData: questionsInitialData,
				staleTime: Infinity,
			},
			{
				...useFastBrainAnswersOptions,
				initialData: answersInitialData,
				staleTime: Infinity,
			},
		],
	});

	const invalidateAnswers = () => queryClient.invalidateQueries({ queryKey: [...answersKey] });

	const [tileGroup]: (TileGroupSelected | undefined)[] = tileGroupSelectedTiles || [];
	const { tiles: selectedTiles } = tileGroup || {};

	return { selectedTiles, questions, answers, invalidateAnswers };
};
