import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const FastBrainTileQuestionsIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Questions"
		heading="Fast Brain"
		link={{ to: '../fast-brain/tile-questions', text: 'Start Tile Questions' }}
	>
		For each of your selected tiles, take a moment to carefully answer the provided questions. This
		will help us understand your preferences and tailor the experience to better suit your needs.
	</IntroLayout>
);

export default FastBrainTileQuestionsIntro;
