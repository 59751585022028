import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AnimatePresence, motion as m, useIsPresent } from 'framer-motion';
import NetworkInterceptor from '@/api/NetworkInterceptor';
import ScrollToTop from './ScrollToTop';

const RootRoute: FunctionComponent = (): ReactElement => {
	const isPresent = useIsPresent();
	const location = useLocation();

	return (
		<NetworkInterceptor>
			<ScrollToTop />
			<Outlet />
			<AnimatePresence mode="wait" initial={false}>
				<m.div
					key={location.pathname}
					initial={{ scaleX: 1 }}
					animate={{ scaleX: 0, transition: { duration: 1, ease: 'circOut' } }}
					style={{ originX: isPresent ? 0 : 1 }}
					className="transition-screen"
				/>
			</AnimatePresence>
		</NetworkInterceptor>
	);
};

export default RootRoute;
