import { useMemo } from 'react';
import { TileGroupSelected } from '@/api/tile-groups/types';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';

const PERSONAL_CATEGORY = 'Personal';
const MONEY_CATEGORY = 'Money';
const VULNERABILITIES_CATEGORY = 'Vulnerabilities';

interface UseSlowBrainSelectedTilesProps {
	currentStep: SlowBrainTileSelectionStep;
	tileGroupsSelectedTiles: TileGroupSelected[];
}

export type SlowBrainStepSelectedTiles = {
	[key in Exclude<SlowBrainTileSelectionStep, 'top_5_selection'>]: TileGroupSelected | undefined;
};

// NOTE: 3D scenes require selected tiles to be an array
// That's why this hook will return `currentSelectedTiles` as an array with only one element
// And abstract away this inconvenience
interface UseSlowBrainSelectedTiles {
	stepSelectedTiles: SlowBrainStepSelectedTiles;
	currentStepSelectedTiles: TileGroupSelected[] | undefined;
}

export const useSlowBrainSelectedTiles = ({
	currentStep,
	tileGroupsSelectedTiles,
}: UseSlowBrainSelectedTilesProps): UseSlowBrainSelectedTiles => {
	const personalOutcomesSelectedTiles = useMemo(
		() => tileGroupsSelectedTiles.find((group) => group.category === PERSONAL_CATEGORY),
		[tileGroupsSelectedTiles]
	);

	const moneyOutcomesSelectedTiles = useMemo(
		() => tileGroupsSelectedTiles.find((group) => group.category === MONEY_CATEGORY),
		[tileGroupsSelectedTiles]
	);

	const planVulnerabilitiesSelectedTiles = useMemo(
		() => tileGroupsSelectedTiles.find((group) => group.category === VULNERABILITIES_CATEGORY),
		[tileGroupsSelectedTiles]
	);

	const currentStepSelectedTiles = useMemo(() => {
		let selectedTiles: TileGroupSelected | undefined;
		if (currentStep === 'personal_outcomes') selectedTiles = personalOutcomesSelectedTiles;
		if (currentStep === 'money_outcomes') selectedTiles = moneyOutcomesSelectedTiles;
		if (currentStep === 'plan_vulnerabilities') selectedTiles = planVulnerabilitiesSelectedTiles;
		return selectedTiles ? [selectedTiles] : selectedTiles;
	}, [
		currentStep,
		personalOutcomesSelectedTiles,
		moneyOutcomesSelectedTiles,
		planVulnerabilitiesSelectedTiles,
	]);

	const stepSelectedTiles: SlowBrainStepSelectedTiles = {
		personal_outcomes: personalOutcomesSelectedTiles,
		money_outcomes: moneyOutcomesSelectedTiles,
		plan_vulnerabilities: planVulnerabilitiesSelectedTiles,
	};

	return {
		stepSelectedTiles,
		currentStepSelectedTiles,
	};
};
