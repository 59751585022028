import { RiArrowLeftLine } from '@remixicon/react';
import './button-back.scss';

interface ButtonBackProps {
	disabled?: boolean;
	onClick?: () => void;
}

const ButtonBack: React.FC<ButtonBackProps> = ({ disabled, onClick }) => (
	<button className="back-button" disabled={disabled} onClick={onClick}>
		<RiArrowLeftLine size={20} /> Back
	</button>
);

export default ButtonBack;
