import { FunctionComponent, ReactElement } from 'react';
import { classNames } from '@/lib/utils';
import BluePattern from '@/assets/blue-pattern.png';
import GreenPattern from '@/assets/green-pattern.png';
import RedPattern from '@/assets/red-pattern.png';
import { SlowBrainTile } from '@/components/ui/slow-brain-tile';
import { SlowBrainTileSelected } from '@/api/tile-groups/types';
import { SlowBrainTileProps } from '@/components/ui/slow-brain-tile/slowBrainTile';
import {
	SLOW_BRAIN_TILE_SELECTION_COLOR_CODE,
	SlowBrainTileSelectionCategories,
} from '@/features/slow-brain/types';

interface SelectedTilesProps {
	tiles: SlowBrainTileProps[];
	classes?: string[];
	onClick?: (tile: SlowBrainTileSelected) => void;
}

const SlowBrainSelectedTiles: FunctionComponent<SelectedTilesProps> = ({
	tiles,
	classes = [],
	onClick = () => {},
}): ReactElement => {
	const imgSrc: Record<SlowBrainTileSelectionCategories, string> = {
		Personal: BluePattern,
		Money: GreenPattern,
		Vulnerabilities: RedPattern,
	};

	return (
		<div className="flex flex-row gap-8 px-[10px] pb-5 pt-4">
			{tiles?.map((t, index) => (
				<SlowBrainTile
					key={index}
					{...t}
					onClick={(tile) => onClick(tile)}
					imgSrc={imgSrc[t.tile.category]}
					isSelected={t.isSelected}
					classes={[
						classNames(index === 0 && 'ml-auto', index === tiles.length - 1 && 'mr-auto'),
						'slow-brain-tile',
						`slow-brain-tile--${SLOW_BRAIN_TILE_SELECTION_COLOR_CODE[t.tile.category]}`,
						...(t.isSelected ? [`slow-brain-tile--selected`] : []),
						...(t.isCompleted ? [`slow-brain-tile--completed`] : []),
						...classes,
					]}
				/>
			))}
		</div>
	);
};

export default SlowBrainSelectedTiles;
