import { RouteObject } from 'react-router-dom';
import PortfolioRecommendationsIntro from '@/views/pages/portfolio-recommendations/intro';
import PortfolioRecommendationsQuestionnaire from '@/views/pages/portfolio-recommendations/questionnaire';
import NextBestActions from '@/views/pages/portfolio-recommendations/output/next-best-actions';
import ClientReport from '@/views/pages/portfolio-recommendations/output/client-report';

export const portfolioRecommendationsRoutes: RouteObject[] = [
	{
		path: 'portfolio-recommendations/intro',
		element: <PortfolioRecommendationsIntro />,
	},
	{
		path: 'portfolio-recommendations/questionnaire',
		element: <PortfolioRecommendationsQuestionnaire />,
	},
	{
		path: 'portfolio-recommendations/next-best-actions',
		element: <NextBestActions />,
	},
	{
		path: 'portfolio-recommendations/client-report',
		element: <ClientReport />,
	},
];
