import { useSignals } from '@preact/signals-react/runtime';
import { useMutation } from '@tanstack/react-query';
import { postSelectedTiles } from '@/api/tile-groups';
import { TileGroup, TileSelectedPayload } from '@/api/tile-groups/types';
import { fastBrainSelectedTiles } from '@/views/scenes/signals/tiles.signals';

interface UseFastBrainSelectTilesProps {
	tileGroup: TileGroup;
	onSuccess: () => void;
}

export const useFastBrainSelectTiles = ({ tileGroup, onSuccess }: UseFastBrainSelectTilesProps) => {
	useSignals();

	return useMutation({
		mutationFn: () => {
			const { id: tileGroupId } = tileGroup;
			const [{ tiles: selectedTiles }] = fastBrainSelectedTiles.value;
			const payload: TileSelectedPayload[] = selectedTiles.map(({ tag, slot }) => ({ tag, slot }));

			return postSelectedTiles(tileGroupId, payload);
		},
		onSuccess,
	});
};
