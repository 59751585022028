import { httpClient } from '@/api/http';
import { OutputOld, Question } from '../types';
import { riskAssessmentQuestions } from './mocks';

export const getRiskAssessmentQuestions = async (): Promise<Question[]> => {
	const { data } = await httpClient.get<Question[]>('/flows/risk_assessment/questions');
	return data;
};

export const mockGetRiskAssessmentQuestions = async (): Promise<Question[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(riskAssessmentQuestions), 500);
	});

export const getRiskAssessmentOutput = async (): Promise<OutputOld> => {
	const { data } = await httpClient.get<OutputOld>('/flows/risk_assessment/output_report');
	return data;
};
