import { Navigate, RouteObject } from 'react-router-dom';
import protectedRoutes from './protected';
import publicRoutes from './public';
import RootRoute from '../RootRoute';

export const routes: RouteObject[] = [
	{
		path: '*',
		element: <RootRoute />,
		children: [
			...protectedRoutes,
			...publicRoutes,
			{
				path: '*',
				element: <Navigate to={'/unauthorized'} />,
			},
		],
	},
];

export default routes;
