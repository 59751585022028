import { RouteObject } from 'react-router-dom';
import { queryClient } from '@/queries';
import FastBrainTileSelectionIntro from '@/views/pages/fast-brain/tile-selection/intro';
import FastBrainTileSelection from '@/views/pages/fast-brain/tile-selection/index';
import FastBrainTileQuestionsIntro from '@/views/pages/fast-brain/tile-questions/intro';
import FastBrainTileQuestions from '@/views/pages/fast-brain/tile-questions/index';
import FastBrainOutput from '@/views/pages/fast-brain/output';
import { tileQuestionsLoader, tileSelectionLoader } from './loaders';

export const fastBrainRoutes: RouteObject[] = [
	{
		path: 'fast-brain/tile-selection/intro',
		element: <FastBrainTileSelectionIntro />,
	},
	{
		path: 'fast-brain/tile-selection',
		element: <FastBrainTileSelection />,
		loader: ({ params: { clientId } }) => tileSelectionLoader(queryClient, clientId as string),
	},
	{
		path: 'fast-brain/tile-questions/intro',
		element: <FastBrainTileQuestionsIntro />,
	},
	{
		path: 'fast-brain/tile-questions',
		element: <FastBrainTileQuestions />,
		loader: ({ params: { clientId } }) => tileQuestionsLoader(queryClient, clientId as string),
	},
	{
		path: 'fast-brain/output',
		element: <FastBrainOutput />,
	},
];
