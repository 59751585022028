export const ChevronDownSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9407 19.059C11.5267 19.6449 12.4782 19.6449 13.0642 19.059L22.0642 10.059C22.6501 9.47305 22.6501 8.52148 22.0642 7.93555C21.4782 7.34961 20.5267 7.34961 19.9407 7.93555L12.0001 15.8762L4.05947 7.94023C3.47354 7.3543 2.52197 7.3543 1.93604 7.94023C1.3501 8.52617 1.3501 9.47773 1.93604 10.0637L10.936 19.0637L10.9407 19.059Z"
			fill="currentColor"
		/>
	</svg>
);
