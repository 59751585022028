export const ArrowRightSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.5594 13.059C22.1453 12.473 22.1453 11.5215 21.5594 10.9355L14.0594 3.43555C13.4734 2.84961 12.5219 2.84961 11.9359 3.43555C11.35 4.02148 11.35 4.97305 11.9359 5.55898L16.8812 10.4996H2.5C1.67031 10.4996 1 11.1699 1 11.9996C1 12.8293 1.67031 13.4996 2.5 13.4996H16.8766L11.9406 18.4402C11.3547 19.0262 11.3547 19.9777 11.9406 20.5637C12.5266 21.1496 13.4781 21.1496 14.0641 20.5637L21.5641 13.0637L21.5594 13.059Z"
			fill="currentColor"
		/>
	</svg>
);
