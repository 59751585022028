import { httpClient } from '@/api/http';
import { AnswerPayload } from './types';

export const postAnswers = async (payload: Array<AnswerPayload>): Promise<void> => {
	const { data } = await httpClient.post<void>('/answers', payload);
	return data;
	// return new Promise((resolve) => {
	// 	setTimeout(() => resolve(), 500);
	// });
};
