import { useMemo } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { StepStatus } from '@/components/ui/progress/progress-steps/progress-steps.types';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';
import {
	slowBrainMoneySelectedTiles,
	slowBrainPersonalSelectedTiles,
	slowBrainTop5SelectedTiles,
	slowBrainVulnerabilitiesSelectedTiles,
} from '@/views/scenes/signals/tiles.signals';
import { SlowBrainStepSelectedTiles } from './useSlowBrainSelectedTiles';
import { CategoryName, TileGroupSelected } from '@/api/tile-groups/types';

interface UseSlowBrainStepStatusesProps {
	currentStep: SlowBrainTileSelectionStep;
	stepSelectedTiles: SlowBrainStepSelectedTiles;
	tileGroupsSelectedTiles: TileGroupSelected[];
}

type UseSlowBrainStepStatuses = {
	[key in SlowBrainTileSelectionStep]: StepStatus;
};

export const useSlowBrainStepStatuses = ({
	currentStep,
	stepSelectedTiles,
	tileGroupsSelectedTiles,
}: UseSlowBrainStepStatusesProps): UseSlowBrainStepStatuses => {
	useSignals();

	const {
		personal_outcomes: personalSelectedTiles,
		money_outcomes: moneySelectedTiles,
		plan_vulnerabilities: vulnerabilitiesSelectedTiles,
	} = stepSelectedTiles;

	const checkIsStepPassed = (categoryToCheck: CategoryName) => {
		if (!Array.isArray(tileGroupsSelectedTiles)) return false;

		const category = tileGroupsSelectedTiles?.find((tl) => tl.category === categoryToCheck);
		return !!category?.tiles.length;
	};

	const personalStepStatus = useMemo(() => {
		if (currentStep === 'personal_outcomes') return StepStatus.ACTIVE;
		if (checkIsStepPassed('Personal')) return StepStatus.COMPLETED;
		return StepStatus.INACTIVE;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, slowBrainPersonalSelectedTiles.value, personalSelectedTiles]);

	const moneyOutcomesStepStatus = useMemo(() => {
		if (currentStep === 'money_outcomes') return StepStatus.ACTIVE;
		if (checkIsStepPassed('Money')) return StepStatus.COMPLETED;
		return StepStatus.INACTIVE;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, slowBrainMoneySelectedTiles.value, moneySelectedTiles]);

	const planVulnerabilitiesStepStatus = useMemo(() => {
		if (currentStep === 'plan_vulnerabilities') return StepStatus.ACTIVE;
		if (checkIsStepPassed('Vulnerabilities')) return StepStatus.COMPLETED;
		return StepStatus.INACTIVE;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, slowBrainVulnerabilitiesSelectedTiles.value, vulnerabilitiesSelectedTiles]);

	const top5SelectionStatus = useMemo(() => {
		if (currentStep === 'top_5_selection') return StepStatus.ACTIVE;
		return StepStatus.INACTIVE;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, slowBrainTop5SelectedTiles.value]);

	return {
		personal_outcomes: personalStepStatus,
		money_outcomes: moneyOutcomesStepStatus,
		plan_vulnerabilities: planVulnerabilitiesStepStatus,
		top_5_selection: top5SelectionStatus,
	};
};
