import { useMemo } from 'react';
import { TileGroup } from '@/api/tile-groups/types';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';

const PERSONAL_CATEGORY = 'Personal';
const MONEY_CATEGORY = 'Money';
const VULNERABILITIES_CATEGORY = 'Vulnerabilities';

interface UseSlowBrainTileGroupsProps {
	currentStep: SlowBrainTileSelectionStep;
	tileGroups: TileGroup[];
}

export type SlowBrainStepsTileGroups = {
	[key in Exclude<SlowBrainTileSelectionStep, 'top_5_selection'>]: TileGroup | undefined;
};

// NOTE: 3D scenes require tile group to be an array
// That's why this hook will return `currentTileGroup` as an array with only one element
// And abstract away this inconvenience
interface UseSlowBrainTileGroups {
	stepTileGroups: SlowBrainStepsTileGroups;
	currentStepTileGroup: TileGroup[] | undefined;
}

export const useSlowBrainTileGroups = ({
	currentStep,
	tileGroups,
}: UseSlowBrainTileGroupsProps): UseSlowBrainTileGroups => {
	const personalOutcomesTileGroup = useMemo(
		() => tileGroups?.find((t) => t.category === PERSONAL_CATEGORY),
		[tileGroups]
	);
	const moneyOutcomesTileGroup = useMemo(
		() => tileGroups?.find((t) => t.category === MONEY_CATEGORY),
		[tileGroups]
	);
	const planVulnerabilitiesTileGroup = useMemo(
		() => tileGroups?.find((t) => t.category === VULNERABILITIES_CATEGORY),
		[tileGroups]
	);

	const currentStepTileGroup = useMemo(() => {
		let currentGroup: TileGroup | undefined;
		if (currentStep === 'personal_outcomes') currentGroup = personalOutcomesTileGroup;
		if (currentStep === 'money_outcomes') currentGroup = moneyOutcomesTileGroup;
		if (currentStep === 'plan_vulnerabilities') currentGroup = planVulnerabilitiesTileGroup;
		return currentGroup ? [currentGroup] : currentGroup;
	}, [
		currentStep,
		personalOutcomesTileGroup,
		moneyOutcomesTileGroup,
		planVulnerabilitiesTileGroup,
	]);

	const stepTileGroups: SlowBrainStepsTileGroups = {
		personal_outcomes: personalOutcomesTileGroup,
		money_outcomes: moneyOutcomesTileGroup,
		plan_vulnerabilities: planVulnerabilitiesTileGroup,
	};

	return { stepTileGroups, currentStepTileGroup };
};
