import { FunctionComponent, ReactElement } from 'react';
import { motion as m } from 'framer-motion';
import PageLayout from '@/views/layouts/page-layout';
import Footer from '@/components/ui/footer';

const PageUnauthorized: FunctionComponent = (): ReactElement => {
	const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

	const itemVariants = {
		hidden: { opacity: 0, y: 10 },
		visible,
	};

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			centerContent
		>
			<m.div
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, transition: { duration: 1 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
				className="flex flex-col gap-6"
			>
				<m.h1 variants={itemVariants} className="text-[54px] font-bold leading-none">
					Oops! Unauthorized Access
				</m.h1>
				<m.p variants={itemVariants} className="text-body-18-md text-body">
					{`Looks like you've tried to access a restricted area.`}
				</m.p>
			</m.div>
		</PageLayout>
	);
};

export default PageUnauthorized;
