import { RouteObject } from 'react-router-dom';
import Home from '@/views/pages/home';

export const defaultRoutes: RouteObject[] = [
	{
		path: '',
		index: true,
		element: <Home />,
	},
];
