import { Question, QuestionType } from '../types';

export const portfolioRecommendationQuestions: Question[] = [
	{
		id: '4b2fdfc2-1113-40a3-93c9-9fd5719814d1',
		flow: 'recommendation',
		category: null,
		tile: null,
		tag: null,
		type: QuestionType.SINGLE_CHOICE,
		choices: [
			{
				id: '685b2241-a336-4b3d-bdb1-d0a6c2b4f409',
				text: 'YES',
			},
			{
				id: '8cf844d2-00cc-4299-8dd5-12d43651ac19',
				text: 'NO',
			},
		],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'You intend to withdraw principal from this account for a major purchase.',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
	{
		id: '5f87305d-9df7-495a-8075-976f3384f0b1',
		flow: 'recommendation',
		category: null,
		tile: null,
		tag: null,
		type: QuestionType.SINGLE_CHOICE,
		choices: [
			{
				id: '45296b04-141e-4c19-a767-d29457f0e963',
				text: 'YES',
			},
			{
				id: 'b62d361d-3ec4-4464-881c-05bc4e12d07a',
				text: 'NO',
			},
		],
		orderNo: 2,
		questionMetadata: [
			{
				label: '',
				text: 'You believe markets are efficient and it is impossible to time the market.',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
	{
		id: '0a615329-857e-4895-bf1a-fdfac2357bbd',
		flow: 'recommendation',
		category: null,
		tile: null,
		tag: null,
		type: QuestionType.SINGLE_CHOICE,
		choices: [
			{
				id: '1c65ae11-0a3b-4f52-8300-ee940ec902e3',
				text: 'YES',
			},
			{
				id: 'cbc8f960-ce93-479d-a6cb-19cfe3408358',
				text: 'NO',
			},
		],
		orderNo: 3,
		questionMetadata: [
			{
				label: '',
				text: 'You would like your portfolio to represent your socially concisous belief system.',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
	{
		id: 'bb32c36e-c169-4932-9ffb-8aadcb5596a9',
		flow: 'recommendation',
		category: null,
		tile: null,
		tag: null,
		type: QuestionType.SINGLE_CHOICE,
		choices: [
			{
				id: '33af0b66-379f-40b8-9d62-613b09fc7d1c',
				text: 'YES',
			},
			{
				id: 'b74c4d53-faa5-470a-93ac-9f05ca667dc7',
				text: 'NO',
			},
		],
		orderNo: 4,
		questionMetadata: [
			{
				label: '',
				text: 'You believe the use of Alternative Investments (i.e.: Private Equity, Private Debt, Real Estate, etc.) will reduce volatility and potentially smoothen out returns over longer periods of time, and are you willing to accept liquidity limitations on that portion of your portfolio.',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
	{
		id: '702649c4-078c-4450-86e7-80fdc98526cc',
		flow: 'recommendation',
		category: null,
		tile: null,
		tag: null,
		type: QuestionType.SINGLE_CHOICE,
		choices: [
			{
				id: '94e46028-e2e9-47f6-b8a4-2d7560e458cc',
				text: 'YES',
			},
			{
				id: '464773e5-a35a-4f19-a84f-55b9b080696e',
				text: 'NO',
			},
		],
		orderNo: 5,
		questionMetadata: [
			{
				label: '',
				text: 'You believe that owning individual equities (stocks) can provide similar diversification to a mutual fund/exchange traded fund.',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
];
