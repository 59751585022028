import { RiskAssessmentCategory, RiskAssessmentStep } from './types';

interface RiskAssessmentStepDetails {
	category: RiskAssessmentCategory;
	name: string;
}

export const RISK_ASSESSMENT_STEP_MAP: Record<RiskAssessmentStep, RiskAssessmentStepDetails> = {
	general_investment: {
		category: 'General Investment Questions',
		name: 'General Investment',
	},
	investment_behavior: {
		category: 'Investment Behavior Questions',
		name: 'Investment Behavior',
	},
	emotional_risk_tolerance: {
		category: 'Emotional Risk Tolerance',
		name: 'Emotional Risk Tolerance',
	},
};
