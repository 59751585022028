import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '@/lib/utils';

interface FooterProps {
	withBoxShadow?: boolean;
	itemsPosition?: 'center' | 'start';
	size?: 'lg' | 'md';
	controls?: ReactNode;
	classes?: Array<string>;
}

const Footer: FunctionComponent<FooterProps> = ({
	withBoxShadow = true,
	itemsPosition = 'center',
	size = 'lg',
	controls,
	classes = [],
}): ReactElement => {
	const year = new Date().getFullYear();

	return (
		<footer
			className={classNames(
				'footer',
				'w-full',
				'print:hidden',
				withBoxShadow && 'footer--shadow',
				size === 'lg' ? 'px-[60px]' : 'px-[40px]'
			)}
		>
			<div
				className={classNames(
					'container',
					'flex',
					`items-${itemsPosition}`,
					'justify-between',
					size === 'lg' ? 'h-[72px]' : 'h-[40px]',
					...classes
				)}
			>
				<div className="flex">
					<p className="text-neutral-700">
						© {year} Concurrent <span className="mx-7">|</span>
					</p>
					<nav className="text-neutral-700">
						<Link to="/">Terms & Conditions</Link>
						<Link to="/" className="ml-7">
							FAQ
						</Link>
					</nav>
				</div>
				{controls && <div className="controls">{controls}</div>}
			</div>
		</footer>
	);
};

export default Footer;
