import { AbstractMesh, AnimationGroup } from '@babylonjs/core';
import {
	TileName,
	TileTag,
	Tile,
	TileSelected,
	TileGroup,
	TileGroupSelected,
} from '@/api/tile-groups/types';

export type TileData = Tile;

export type InternalTileData = {
	name: string;
	mesh: AbstractMesh;
	dropAnimation: AnimationGroup | null;
	stackingAnimation: AnimationGroup | null;
	hoverAnimation: AnimationGroup | null;
	isSelected: boolean;
	tileTag: TileTag;
	tileText: TileName;
	textTarget: AbstractMesh;
	initialPosition: [number, number, number];
};

export type SlotData = {
	id: number;
	isOccupied: boolean;
	tile: TileData | null;
	position: [number, number, number];
};

export type HandData = {
	name: string;
	mesh: AbstractMesh;
	animation: AnimationGroup | null;
};

export type LoadResult<TileType> = {
	tiles: TileType[];
	slots: SlotData[];
	handData: HandData | null;
};

export interface TileSceneProps {
	tilesGroup: TileGroup[];
	selectedTilesGroup: TileGroupSelected[];
}
export type SlowBrainCategory = 'Personal' | 'Money' | 'Vulnerabilities';

export type CategorizedTiles = {
	tiles: TileData[];
	category: SlowBrainCategory;
};

export type CategorizedSelectedTiles = {
	tiles: TileSelected[];
	category: SlowBrainCategory;
};

// Domino material naming convention coming from blender, need to not change these when exporting from blender
export type TileMaterialCategory =
	| 'PersonalOutcomes_material'
	| 'MoneyOutcomes_material'
	| 'PlanVulnerabilities_material'
	| 'fastbrain_material';

// ADJUST TILE COLOR AND FONT COLOR PER CATEGORY
export enum TileFontColor {
	FastBrain = '#084B83',
	Personal = '#0A2C48',
	Money = '#0D3005',
	Vulnerabilities = '#4A2404',
	Default = '#000000',
}

export enum TileColor {
	FastBrain = '#DDDDDD',
	Personal = '#B3CAE5',
	Money = '#BACBB5',
	Vulnerabilities = '#D0BEA7',
}
