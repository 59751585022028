import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getFastBrainQuestions } from '@/api/questions/fast-brain';
import { getFastBrainAnswers } from '@/api/answers/fast-brain';
import {
	getFastBrainTileGroups,
	getFastBrainTileGroupSelectedTiles,
} from '@/api/tile-groups/fast-brain';
import { AnswerPayload } from '@/api/answers/types';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { postAnswers } from '@/api/answers';

export const fastBrainKeys = {
	questions: ['fast_brains_questions'],
	answers: ['fast_brain_answers'],
	tileGroups: ['fast_brain_tile_groups'],
	tileGroupsSelectedTiles: ['fast_brain_tile_groups_selected_tiles'],
};

// Query Options
export const useFastBrainQuestionsOptions = {
	queryKey: fastBrainKeys.questions,
	queryFn: () => getFastBrainQuestions(),
};

export const useFastBrainAnswersOptions = {
	queryKey: fastBrainKeys.answers,
	queryFn: () => getFastBrainAnswers(),
};

export const useFastBrainTileGroupsOptions = {
	queryKey: fastBrainKeys.tileGroups,
	queryFn: () => getFastBrainTileGroups(),
};

export const useFastBrainTileGroupsSelectedTilesOptions = {
	queryKey: fastBrainKeys.tileGroupsSelectedTiles,
	queryFn: () => getFastBrainTileGroupSelectedTiles(),
};

// Queries
export const useFastBrainQuestions = () => useQuery(useFastBrainQuestionsOptions);

export const useFastBrainAnswers = () => useQuery(useFastBrainAnswersOptions);

export const useFastBrainQuestionsAndAnswers = () =>
	useQueries({ queries: [useFastBrainQuestionsOptions, useFastBrainAnswersOptions] });

export const usePostFastBrainAnswers = (questionnaire: QuestionAnswer) => {
	const navigate = useNavigate();

	return useMutation({
		mutationFn: () => {
			const payload: AnswerPayload[] = Object.entries(questionnaire).map(
				([questionId, answer]) => ({ questionId, answerText: answer as string })
			);
			return postAnswers(payload);
		},
		onSuccess: () => navigate('../fast-brain/output'),
	});
};
