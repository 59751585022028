import { useDebouncedCallback } from 'use-debounce';
import { MutableRefObject, useCallback, useEffect } from 'react';

type useScrollProps = {
	questionRefs: MutableRefObject<(HTMLDivElement | null)[]>;
	scrollElementRef: MutableRefObject<HTMLDivElement | null>;
	currentQuestion: number;
	setCurrentQuestion: (question: number) => void;
};

const useQuestionGroupScroll = ({
	questionRefs,
	scrollElementRef,
	currentQuestion,
	setCurrentQuestion,
}: useScrollProps) => {
	const getVisibleIndex = useCallback(() => {
		let visibleIndex = null;
		if (questionRefs.current && questionRefs.current.length)
			questionRefs.current?.forEach((el, i) => {
				const parent = scrollElementRef.current?.getBoundingClientRect();
				const child = el?.getBoundingClientRect();
				if (parent && child)
					if (
						Math.floor(child?.top) === Math.floor(parent?.top) ||
						Math.floor(child?.bottom) === Math.floor(parent?.bottom) ||
						(child?.top <= parent?.top && child?.bottom >= parent?.bottom) ||
						(child?.top >= parent?.top && child?.bottom <= parent?.bottom)
					)
						visibleIndex = i;
			});
		return visibleIndex;
	}, [questionRefs, scrollElementRef]);

	const updateCurrentQuestion = () => {
		const visibleIndex = getVisibleIndex();
		if (visibleIndex !== null) setCurrentQuestion(visibleIndex);
	};

	const updateCurrentQuestionDebounced = useDebouncedCallback(updateCurrentQuestion, 300);

	useEffect(() => {
		scrollElementRef.current?.addEventListener('scroll', updateCurrentQuestionDebounced);
		return () => {
			window.removeEventListener('scroll', updateCurrentQuestionDebounced);
		};
	}, [scrollElementRef, updateCurrentQuestionDebounced]);

	useEffect(() => {
		const visibleIndex = getVisibleIndex();
		if (visibleIndex !== null && visibleIndex !== currentQuestion)
			questionRefs.current[currentQuestion]?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
	}, [currentQuestion, getVisibleIndex, questionRefs]);
};

export default useQuestionGroupScroll;
