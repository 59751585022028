import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AnswerChoicesPayload } from '@/api/answers/types';
import { Question, RiskLevel } from '@/api/questions/types';
import { postAnswers } from '@/api/answers';
import {
	usePortfolioRecommendationQuestionsAndAnswers,
	usePortfolioRecommendationRiskToleranceLevel,
} from '@/queries/portfolioRecommendationQueries';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { PortfolioRecommendationsForm } from '@/features/portfolio-recommendations';

const PortfolioRecommendationsQuestionnaire: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const [questionnaire, setQuestionnaire] = useState<Record<string, string[]>>({});
	const [riskToleranceData, setRiskToleranceData] = useState<RiskLevel>();

	const onAnswerChange = (questionId: Question['id'], answer: string) => {
		setQuestionnaire({ ...questionnaire, [questionId]: [answer] });
	};

	const [
		{ data: questions, isFetched: isQuestionsFetched },
		{ data: answers, isFetched: isAnswersFetched },
	] = usePortfolioRecommendationQuestionsAndAnswers();

	const { data: riskTolerance, isFetched: isRiskLevelFetched } =
		usePortfolioRecommendationRiskToleranceLevel();

	useEffect(() => {
		if (!questions || !isQuestionsFetched) return;
		if (!answers || !isAnswersFetched) return;

		const questionnaire = questions.reduce(
			(prev, curr) => ({
				...prev,
				[curr.id]: [...(answers.find((a) => a.question.id === curr.id)?.answer?.answerIds || [])],
			}),
			{}
		);

		setQuestionnaire(questionnaire);
		setRiskToleranceData(riskTolerance);
	}, [questions, isQuestionsFetched, answers, isAnswersFetched, riskTolerance, isRiskLevelFetched]);

	const { mutate: submitAnswers, isPending: isSubmitting } = useMutation({
		mutationFn: () => {
			const requestPayload: AnswerChoicesPayload[] = Object.entries(questionnaire).map(
				([key, value]) => ({ questionId: key, choices: value })
			);
			return postAnswers(requestPayload);
		},
		onSuccess: () => navigate('../portfolio-recommendations/next-best-actions'),
	});

	const isDisabled = useMemo(
		() => isSubmitting || !Object.entries(questionnaire).every(([, answer]) => !!answer.length),
		[isSubmitting, questionnaire]
	);
	const isContentReady = useMemo(
		() => isQuestionsFetched && isAnswersFetched,
		[isQuestionsFetched, isAnswersFetched]
	);

	if (!isContentReady) return <></>;

	return (
		<div className="flex min-h-[100%] flex-col bg-neutral-page">
			<Header titleText="Portfolio Recommendations" />
			<main className="container flex flex-1 items-center justify-center p-16">
				<div className="m-auto flex w-full max-w-[872px] flex-col gap-12">
					<div className="w-full rounded-lg border-2 border-blue-primary bg-blue-50 p-6 text-center">
						<h1 className="text-heading-24">
							Your Risk Profile:{' '}
							{riskToleranceData && (
								<span className="text-blue-dark">{riskToleranceData?.riskToleranceLevel}</span>
							)}
						</h1>
					</div>
					<div className="flex flex-col gap-8">
						<h2 className="text-heading-24 text-blue-dark">Consideration Questions</h2>
						{!!questions?.length && (
							<PortfolioRecommendationsForm
								questions={questions}
								answers={questionnaire}
								onAnswerChange={onAnswerChange}
								onSubmit={submitAnswers}
								disabled={isDisabled}
							/>
						)}
					</div>
				</div>
			</main>
			<Footer withBoxShadow={false} itemsPosition="start" size="md" />
		</div>
	);
};

export default PortfolioRecommendationsQuestionnaire;
