import { useQueries, useQuery } from '@tanstack/react-query';
import {
	getPortfolioRecommendationQuestions,
	getRiskToleranceLevel,
} from '@/api/questions/portfolio-recommendations';
import { getPortfolioRecommendationsAnswers } from '@/api/answers/portfolio-recommendations';

export const portfolioRecommendationKeys = {
	questions: ['portfolio_recommendation_questions'],
	answers: ['portfolio_recommendations_answers'],
	riskToleranceLevel: ['portfolio_recommendation_risk_tolerance_level'],
};

export const usePortfolioRecommendationQuestionsOptions = () => ({
	queryKey: portfolioRecommendationKeys.questions,
	queryFn: () => getPortfolioRecommendationQuestions(),
});

export const usePortfolioRecommendationQuestions = () =>
	useQuery(usePortfolioRecommendationQuestionsOptions());

export const usePortfolioRecommendationAnswersOptions = () => ({
	queryKey: portfolioRecommendationKeys.answers,
	queryFn: () => getPortfolioRecommendationsAnswers(),
});

export const usePortfolioRecommendationAnswers = () =>
	useQuery(usePortfolioRecommendationAnswersOptions());

export const usePortfolioRecommendationRiskToleranceLevelOptions = () => ({
	queryKey: portfolioRecommendationKeys.riskToleranceLevel,
	queryFn: () => getRiskToleranceLevel(),
});

export const usePortfolioRecommendationRiskToleranceLevel = () =>
	useQuery(usePortfolioRecommendationRiskToleranceLevelOptions());

export const usePortfolioRecommendationQuestionsAndAnswers = () =>
	useQueries({
		queries: [
			usePortfolioRecommendationQuestionsOptions(),
			usePortfolioRecommendationAnswersOptions(),
		],
	});
