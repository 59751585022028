import { FunctionComponent, ReactElement } from 'react';
import { TileProps, FastBrainTile } from '@/components/ui/tile';

interface SelectedTilesProps {
	tiles: TileProps[];
}

const FastBrainSelectedTiles: FunctionComponent<SelectedTilesProps> = ({ tiles }): ReactElement => (
	<div className="flex flex-row gap-8 p-4">
		{tiles.map((tile, index) => (
			<FastBrainTile key={index} text={tile.text} />
		))}
	</div>
);

export default FastBrainSelectedTiles;
