import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const FastBrainTileSelectionIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Selection"
		heading="Fast Brain"
		link={{ to: '../fast-brain/tile-selection', text: 'Start Tile Selection' }}
	>
		While we like to believe our decisions are rooted in logic or the pursuit of maximum
		satisfaction, the reality is that we often rely on mental shortcuts. These shortcuts, or
		&quot;rules of thumb&quot;, are informed by our past experiences and help streamline our
		decision-making process.
	</IntroLayout>
);

export default FastBrainTileSelectionIntro;
