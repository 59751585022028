import { FunctionComponent, ReactElement } from 'react';
import { Section } from '@/api/questions/types';
import Card from './Card';
import '@/scss/_output.scss';

interface StackingCardProps {
	sections: Section[] | undefined;
}

const StackingCards: FunctionComponent<StackingCardProps> = ({ sections }): ReactElement => {
	if (!sections) return <></>;

	return (
		<div className="cards-container">
			<ul id="cards">
				{sections.map((sc, i) => (
					<Card key={sc.header} item={sc} ind={i} />
				))}
			</ul>
		</div>
	);
};

export default StackingCards;
