import { forwardRef, ReactElement, useEffect, useRef } from 'react';
import { TextInput } from '@/components/ui/inputs';
import QuestionAbstract, { QuestionAbstractProps } from './QuestionAbstract';

export interface QuestionOpenProps extends Omit<QuestionAbstractProps, 'children'> {
	answer: string;
	onChange: (questionId: string, answer: string) => void;
	onPressEnter?: () => void;
	placeholder?: string;
	showInfo?: boolean;
	isFocused?: boolean;
}

export const QuestionOpen = forwardRef<HTMLDivElement, QuestionOpenProps>(
	(
		{
			question,
			answer,
			onChange,
			onPressEnter,
			placeholder,
			showInfo,
			isFocused,
			...questionAbstractProps
		},
		ref
	): ReactElement => {
		const textInputRef = useRef<HTMLTextAreaElement>(null);

		// Control focused state
		useEffect(() => {
			if (isFocused) {
				textInputRef.current?.focus({ preventScroll: true });
			} else {
				textInputRef.current?.blur();
			}
		}, [isFocused]);

		return (
			<QuestionAbstract {...{ ...questionAbstractProps, question }} ref={ref}>
				<TextInput
					ref={textInputRef}
					id={`${question.id}-question`}
					name={`${question.id}-question`}
					value={answer}
					onChange={(answer) => onChange(question.id, answer)}
					onPressEnter={onPressEnter}
					placeholder={placeholder}
					showInfo={showInfo}
				/>
			</QuestionAbstract>
		);
	}
);

QuestionOpen.displayName = 'Question Open';

export default QuestionOpen;
