import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const PortfolioRecommendationsIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		heading="Portfolio Recommendations"
		link={{
			to: '../portfolio-recommendations/questionnaire',
			text: 'View Portfolio Recommendations',
		}}
	>
		The recommendations section is designed to align investment recommendations with your financial
		needs. The section begins with portfolio recommendations, continues with a response analysis,
		and finishes with a summarized report of your results.
	</IntroLayout>
);

export default PortfolioRecommendationsIntro;
