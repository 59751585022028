import { QuestionType } from '@/api/questions/types';
import { Answer } from '../types';

export const slowBrainAnswer: Answer[] = [
	{
		question: {
			id: '550e8400-e29b-41d4-a716-446655440000',
			type: QuestionType.MULTIPLE_CHOICE,
			questionMetadata: [
				{
					label: '',
					text: 'If you were to move well into the future and look back on your life, what are the Personal Outcomes you would want to know came true?',
					subtext: '',
					hint: '',
					imageUrl: null,
				},
			],
			choices: [
				{
					id: '550e8400-e29b-41d4-a716-446655440001',
					text: 'General Investment Questions',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440002',
					text: 'You Only Live Once',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440003',
					text: 'Learn From Your Mistakes',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440004',
					text: 'Learn From Your Mistakes',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440005',
					text: 'Nothing Ventured, Nothing Gained',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440006',
					text: 'The More You Give, The More You Receive',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440007',
					text: `Don't Put All Your Eggs in One Basket`,
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440008',
					text: 'A Fool and His Money Are Soon Parted',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440009',
					text: 'Charity Begins at Home',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440010',
					text: 'Less Can Be More',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440011',
					text: 'Fear and Greed Are Your Enemies',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440012',
					text: 'Money is the Root of All Evils',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440013',
					text: 'Go Big or Go Home',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440014',
					text: 'Embrace Volatility',
				},
				{
					id: '550e8400-e29b-41d4-a716-446655440015',
					text: 'Stay the Course',
				},
			],
			flow: 'slow_brain',
			category: '',
			tag: '',
			tile: '',
			orderNo: 0,
		},

		answer: {
			answerText: null,
			answerIds: ['550e8400-e29b-41d4-a716-446655440001', '550e8400-e29b-41d4-a716-446655440002'],
		},
	},
];

export const slowBrainAnswers: Answer[] = [
	{
		question: {
			id: '9d3bdd5b-7f5c-4ac3-9e20-dcc5b3ca99fd',
			type: QuestionType.OPEN,
			flow: 'slow_brain',
			category: '',
			tag: '',
			tile: '',
			choices: [],
			orderNo: 1,
			questionMetadata: [
				{
					label: '',
					text: 'On the tiles you’ve chosen, can you identify a specific instance in your life where you can trace where this “rule of thumb” came from?',
					subtext: '',
					hint: '',
					imageUrl: null,
				},
			],
		},
		answer: {
			answerText: 'Lorem ipsum dolor.',
			answerIds: null,
		},
	},
];
