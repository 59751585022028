import { useMutation } from '@tanstack/react-query';
import { AnswerPayload } from '@/api/answers/types';
import { QuestionAnswer } from '@/shared/types/questions.types';
import { postAnswers } from '@/api/answers';

interface UseFastBrainAnswerQuestionsProps {
	questionnaire: QuestionAnswer;
	onSuccess: () => void;
}

export const useFastBrainAnswerQuestions = ({
	questionnaire,
	onSuccess,
}: UseFastBrainAnswerQuestionsProps) =>
	useMutation({
		mutationFn: () => {
			const payload: AnswerPayload[] = Object.entries(questionnaire).map(
				([questionId, answer]) => ({ questionId, answerText: answer as string })
			);
			return postAnswers(payload);
		},
		onSuccess,
	});
