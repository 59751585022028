import { FunctionComponent, ReactElement } from 'react';
import { ListUlSolid } from '@/assets/icons/ListUlSolid';
import BadgeLayout from '../BadgeLayout';

const ListBadge: FunctionComponent = (): ReactElement => (
	<BadgeLayout>
		<ListUlSolid />
	</BadgeLayout>
);

export default ListBadge;
