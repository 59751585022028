import AbstractControlInput, {
	AbstractControlInputProps,
} from '../abstract-control-input/AbstractControlInput';

type CheckboxInputProps = Omit<AbstractControlInputProps, 'type'>;

const CheckboxInput: React.FC<CheckboxInputProps> = ({ ...props }) => (
	<AbstractControlInput type="checkbox" {...props} />
);

export default CheckboxInput;
