import { FunctionComponent, ReactElement } from 'react';
import { motion as m } from 'framer-motion';
import Footer from '@/components/ui/footer';
import PageLayout from '@/views/layouts/page-layout';
import LinkButton from '@/components/ui/link-button';
import '../../../scss/_page-not-found.scss';

const PageNotFound: FunctionComponent = (): ReactElement => {
	const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

	const itemVariants = {
		hidden: { opacity: 0, y: 10 },
		visible,
	};
	return (
		<div className="page-not-found-container">
			<PageLayout
				theme="light"
				footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			>
				<div className="page-not-found">
					<m.div
						initial="hidden"
						animate="visible"
						exit={{ opacity: 0, transition: { duration: 1 } }}
						variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
						className="flex flex-col justify-start"
					>
						<m.h1 variants={itemVariants} className="text-body">
							Oops! Page Not Found
						</m.h1>
						<m.p variants={itemVariants} className="my-6 text-body-18-md text-body">
							It looks like the page you’re looking for doesn’t exist.
						</m.p>
						<m.div variants={itemVariants}>
							<LinkButton to="/">Go to the Home Page</LinkButton>
						</m.div>
					</m.div>
				</div>
			</PageLayout>
		</div>
	);
};

export default PageNotFound;
