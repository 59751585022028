import { useSignals } from '@preact/signals-react/runtime';
import { useMemo } from 'react';
import { isFastBrainDisabled } from '@/views/scenes/signals/tiles.signals';

interface UseIsFastBrainTileSelectionDisabledProps {
	isSceneLoading: boolean;
	isTileSelectionPending: boolean;
}

export const useIsFastBrainTileSelectionDisabled = ({
	isSceneLoading,
	isTileSelectionPending,
}: UseIsFastBrainTileSelectionDisabledProps) => {
	useSignals();
	return useMemo(
		() => isSceneLoading || isTileSelectionPending || isFastBrainDisabled.value,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isSceneLoading, isTileSelectionPending, isFastBrainDisabled.value]
	);
};
