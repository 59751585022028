import { FunctionComponent, ReactElement, ReactNode } from 'react';

interface BadgeLayoutProps {
	children: ReactNode;
}

const BadgeLayout: FunctionComponent<BadgeLayoutProps> = ({ children }): ReactElement => (
	<div className="flex h-[48px] w-[48px] items-center justify-center rounded-full border-4 border-completed text-completed">
		{children}
	</div>
);

export default BadgeLayout;
