import { TileTag, CategoryName, FlowTag, TileName } from '../tile-groups/types';

export enum QuestionType {
	SINGLE_CHOICE = 'SINGLE_CHOICE',
	MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
	OPEN = 'OPEN',
}

export interface Choice {
	id: string;
	text: string;
}

export interface QuestionMetadata {
	label: string;
	text: string;
	subtext: string;
	hint: string;
	imageUrl: string | null;
}

export interface Question {
	id: string;
	type: QuestionType;
	flow: FlowTag;
	category: CategoryName | null;
	tag: TileTag | null;
	tile: TileName | null;
	choices: Choice[];
	orderNo: number;
	questionMetadata: QuestionMetadata[];
}

// TODO: remove this interface when data structure changed on BE
export interface OutputOld {
	outputText: string;
}

export interface Section {
	header: string;
	text: string[];
}

export interface Output {
	sections: Section[];
}

export interface RiskLevel {
	riskToleranceLevel: string;
}
