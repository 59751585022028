export const ChevronUpSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9407 4.94141C11.5267 4.35547 12.4782 4.35547 13.0642 4.94141L22.0642 13.9414C22.6501 14.5273 22.6501 15.4789 22.0642 16.0648C21.4782 16.6508 20.5267 16.6508 19.9407 16.0648L12.0001 8.12422L4.05947 16.0602C3.47354 16.6461 2.52197 16.6461 1.93604 16.0602C1.3501 15.4742 1.3501 14.5227 1.93604 13.9367L10.936 4.93672L10.9407 4.94141Z"
			fill="currentColor"
		/>
	</svg>
);
