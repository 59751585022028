export const CheckSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.5595 4.94043C22.1454 5.52637 22.1454 6.47793 21.5595 7.06387L9.55947 19.0639C8.97354 19.6498 8.02197 19.6498 7.43604 19.0639L1.43604 13.0639C0.850098 12.4779 0.850098 11.5264 1.43604 10.9404C2.02197 10.3545 2.97354 10.3545 3.55947 10.9404L8.5001 15.8764L19.4407 4.94043C20.0267 4.35449 20.9782 4.35449 21.5642 4.94043H21.5595Z"
			fill="currentColor"
		/>
	</svg>
);
