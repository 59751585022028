export const PlayLargeFill = ({ width = 64, height = 64 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 64 65"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 54.3552V10.6446C16 8.55009 18.3039 7.27315 20.08 8.38323L55.0485 30.2387C56.7197 31.2829 56.7197 33.7168 55.0485 34.7613L20.08 56.6165C18.3039 57.7267 16 56.4499 16 54.3552Z"
			fill="white"
		/>
	</svg>
);
