import { FunctionComponent, ReactElement } from 'react';
import { ClientReportAnswers } from '../types';
import { classNames } from '@/lib/utils';

interface PortfolioRecommendationsConsiderationsProps {
	answers: ClientReportAnswers[];
	classes?: string[];
}

const PortfolioRecommendationsConsiderations: FunctionComponent<
	PortfolioRecommendationsConsiderationsProps
> = ({ answers, classes = [] }): ReactElement => {
	if (!answers.length) return <></>;
	return (
		<div
			className={classNames(
				'flex',
				'flex-col',
				'gap-6',
				'rounded-md',
				'bg-white',
				'p-8',
				'shadow',
				'print:break-inside-avoid',
				'print:bg-inherit',
				'print:p-0',
				'print:shadow-none',
				...classes
			)}
		>
			<div className="flex flex-col gap-4">
				<h2 className="text-heading-24 text-blue-dark">Portfolio Considerations</h2>
				<div className="text-body-16-md">
					{answers.map(({ questionId, questionText, answer }) => (
						<div
							key={questionId}
							className="flex flex-row items-center justify-between gap-4 border-b border-b-neutral-300 border-opacity-30 py-4"
						>
							<p>{questionText}</p>
							<strong>{answer}</strong>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PortfolioRecommendationsConsiderations;
