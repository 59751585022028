import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AnswerPayload } from '@/api/answers/types';
import { postAnswers } from '@/api/answers';
import { QuestionType } from '@/api/questions/types';
import { SlowBrainTileSelected } from '@/api/tile-groups/types';
import { HeaderFlow as Header } from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import SlowBrainSelectedTiles from '@/components/feature/slow-brain-selected-tiles/SlowBrainSelectedTiles';
import {
	QuestionGroupPanel,
	QuestionGroupPanels,
} from '@/components/feature/questions/question-group-panels';
import {
	ServerResponse,
	SlowBrainCurrentQuestions,
	SlowBrainStepAnswers,
} from '@/features/slow-brain/types';
import {
	useSlowBrainQuestions,
	useSlowBrainQuestionsData,
} from '@/features/slow-brain/tile-questions/useSlowBrainQuestionData';

const SlowBrainTileQuestions: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();

	const [questionnaire, setQuestionnaire] = useState<SlowBrainStepAnswers>();
	const [currentQuestion, setCurrentQuestion] = useState<SlowBrainCurrentQuestions>();
	const [currentTile, setCurrentTile] = useState<string>();

	const { selectedTiles, answers } = useSlowBrainQuestionsData();
	const selectedTags: ServerResponse = selectedTiles.reduce(
		(prev, curr) => ({ ...prev, [curr.tag]: curr.tag }),
		{}
	);

	const { questions } = useSlowBrainQuestions(Object.keys(selectedTags), !!selectedTags);

	useEffect(() => {
		if (currentTile) {
			const resetCurrentQuestions = Object.keys(questionnaire || {}).reduce(
				(prev, curr) => ({ ...prev, [curr]: 0 }),
				{}
			);
			setCurrentQuestion(resetCurrentQuestions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTile]);

	useEffect(() => {
		if (!questions) return;
		if (!answers) return;
		if (!selectedTiles) return;

		const questionnaire: SlowBrainStepAnswers = questions.reduce((prev, curr) => {
			const apiTag = curr.tag;
			const step = apiTag as string;

			return {
				...prev,
				[step]: {
					...prev[step],
					[curr.id]:
						answers.find((a) => a.question.id === curr.id)?.answer?.answerText ||
						answers.find((a) => a.question.id === curr.id)?.answer?.answerIds ||
						'',
				},
			};
		}, {} as SlowBrainStepAnswers);

		const currentQuestions = Object.entries(questionnaire).reduce((prev, curr) => {
			const [step] = curr;
			return { ...prev, [step]: 0 };
		}, {} as SlowBrainCurrentQuestions);

		let currentTile;
		// eslint-disable-next-line no-restricted-syntax
		for (const [step, current] of Object.entries(currentQuestions).sort()) {
			if (Object.entries(currentQuestions).every((ent) => ent[1] === 0)) {
				currentTile = selectedTiles[0]?.tag;
				break;
			}

			if (current < Object.entries(questionnaire[step]).length) {
				currentTile = step;
				break;
			}

			const last = Object.entries(currentQuestions).length - 1;
			currentTile = Object.keys(currentQuestions)[last];
		}

		setQuestionnaire(questionnaire);
		setCurrentQuestion(currentQuestions);
		setCurrentTile(currentTile);
	}, [questions, answers, selectedTiles]);

	const { mutate: onSubmit, isPending: isSubmitting } = useMutation({
		mutationFn: (step: string) => {
			const questionAnswers = questionnaire?.[step] || {};
			const payload: AnswerPayload[] = Object.entries(questionAnswers).map(
				([questionId, answer]) => ({
					questionId,
					...(Array.isArray(answer) ? { choices: answer } : { answerText: answer }),
				})
			);
			return postAnswers(payload);
		},
	});

	const panels: QuestionGroupPanel[] = useMemo(() => {
		const panels: QuestionGroupPanel[] = [];

		const isNextDisabled =
			Object.entries(questionnaire?.[currentTile || ''] || {}).some(([, value]) => value === '') ||
			isSubmitting;

		Object.entries(questionnaire || {}).forEach(([step, answers]) => {
			const stepApiTag = step;
			const stepQuestions = questions?.filter((q) => q.tag === stepApiTag) || [];
			const stepCurrentQuestion = currentQuestion?.[step] || 0;

			const nextTile: SlowBrainTileSelected | null =
				selectedTiles.find((st) => st.tag === step) &&
				selectedTiles.findIndex((st) => st.tag === step) < selectedTiles.length - 1
					? selectedTiles[selectedTiles.findIndex((st) => st.tag === step) + 1]
					: null;

			const onNextStep = {
				label: 'Continue',
				callback: () => {
					onSubmit(step);
					if (nextTile) setCurrentTile(nextTile.tag);
					else navigate('../slow-brain/output');
				},
				disabled: isNextDisabled,
			};

			const panelIndex = selectedTiles.findIndex((t) => t.tag === stepApiTag);
			if (panelIndex === -1) return;

			const panel = {
				id: step,
				questionGroup: {
					questions: stepQuestions,
					currentQuestion: stepCurrentQuestion,
					setCurrentQuestion: (question) => {
						setCurrentQuestion(
							currentQuestion ? { ...currentQuestion, [step]: question } : undefined
						);
					},
					answers,
					onNextStep,
					onChange: (questionId, answer, type) => {
						if (questionnaire) {
							setQuestionnaire({
								...questionnaire,
								[step]: {
									...questionnaire[step],
									[questionId]: type === QuestionType.SINGLE_CHOICE ? [answer] : answer,
								},
							});
						}
					},
				},
			} as QuestionGroupPanel;

			panels[panelIndex] = panel;
		});

		return panels;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaire, questions, currentQuestion, selectedTiles, isSubmitting]);

	return (
		<div className="flex h-full flex-col bg-neutral-page">
			<Header titleText="Slow Brain" titleLabel="Tile Questions" />
			<SlowBrainSelectedTiles
				tiles={selectedTiles.map((tile, index) => ({
					tile,
					isSelected: tile.tag === currentTile,
					isCompleted: index < selectedTiles.findIndex((tl) => tl.tag === currentTile),
					disabled: index > selectedTiles.findIndex((tl) => tl.tag === currentTile),
				}))}
				onClick={(tile) => setCurrentTile(tile.tag)}
			/>
			<main className="overflow-hidden">
				{currentTile && <QuestionGroupPanels panels={panels} currentPanelId={currentTile} />}
			</main>
			<Footer withBoxShadow={false} itemsPosition="start" size="md" />
		</div>
	);
};

export default SlowBrainTileQuestions;
