import { CheckSolid } from '@/assets/icons/CheckSolid';
import './control-input.scss';

export interface AbstractControlInputProps {
	id?: string;
	name?: string;
	type: 'checkbox' | 'radio';
	value?: string;
	onChange?: (e: string) => void;
	label?: string;
	withIcon?: boolean;
	checked?: boolean;
}

const AbstractControlInput: React.FC<AbstractControlInputProps> = ({
	id,
	name,
	type,
	value,
	onChange,
	label,
	withIcon = true,
	checked = false,
}) => (
	<div className="control-input__wrapper">
		<input
			id={id}
			name={name}
			type={type}
			value={value}
			onChange={(e) => onChange?.(e.target.value)}
			className="control-input"
			checked={checked}
		/>
		<label htmlFor={id} className="control-input__display">
			{withIcon && (
				<span className="control-input__display__icon">
					<CheckSolid width={16} height={16} />
				</span>
			)}
			{label}
		</label>
	</div>
);

export default AbstractControlInput;
