import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ArrowRightSolid } from '@/assets/icons/ArrowRightSolid';
import { classNames } from '@/lib/utils';
import '../button/button-primary/button-primary.scss';

interface LinkButtonProps extends LinkProps {
	variant?: 'primary' | 'secondary';
	size?: 'lg' | 'md' | 'sm';
	showIcon?: boolean;
	children?: ReactNode;
}

const LinkButton: FunctionComponent<LinkButtonProps> = ({
	variant = 'primary',
	size = 'lg',
	showIcon = true,
	children,
	...rest
}): ReactElement => (
	<Link {...rest} className={classNames('button', `button--${variant}`, `button--${size}`)}>
		{children} {showIcon && <ArrowRightSolid />}
	</Link>
);

export default LinkButton;
