import { useLoaderData } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';
import { SlowBrainTileQuestionsLoaderResult } from '@/router/routes/protected/slow-brain';
import { Answer } from '@/api/answers/types';
import { Question } from '@/api/questions/types';
import { SlowBrainTileSelected } from '@/api/tile-groups/types';
import {
	useSlowBrainAnswersOptions,
	useSlowBrainQuestionOptions,
	useSlowBrainPickedTilesOptions,
} from '@/queries/slowBrainQueries';

interface SlowBrainQuestionsData {
	selectedTiles: SlowBrainTileSelected[];
	answers: Answer[];
}

interface SlowBrainQuestions {
	questions: Question[];
}

export const useSlowBrainQuestionsData = (): SlowBrainQuestionsData => {
	const { tileGroupSelectedTiles: tileGroupSelectedTilesInitialData, answers: answersInitialData } =
		useLoaderData() as SlowBrainTileQuestionsLoaderResult;

	const [{ data: tileGroupSelectedTiles }, { data: answers }] = useQueries({
		queries: [
			{
				...useSlowBrainPickedTilesOptions,
				initialData: tileGroupSelectedTilesInitialData,
				staleTime: Infinity,
			},
			{
				...useSlowBrainAnswersOptions,
				initialData: answersInitialData,
				staleTime: Infinity,
			},
		],
	});

	return { selectedTiles: tileGroupSelectedTiles, answers };
};

export const useSlowBrainQuestions = (data: string[], enabled = false): SlowBrainQuestions => {
	const { data: questions } = useQuery({
		...useSlowBrainQuestionOptions({ enabled, data }),
		initialData: [],
	});

	return { questions };
};
