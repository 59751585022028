import { httpClient } from '@/api/http';
import { Answer } from '../types';
import { fastBrainAnswers } from './mocks';

export const getFastBrainAnswers = async (): Promise<Answer[]> => {
	const { data } = await httpClient.get<Answer[]>('/flows/fast_brain/answers');
	return data;
};

export const mockGetFastBrainAnswers = async (): Promise<Answer[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(fastBrainAnswers), 500);
	});
