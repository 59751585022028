import { httpClient } from '@/api/http';
import { SlowBrainTileSelected, TileGroup, TileGroupSelected, TileSelectedPayload } from '../types';
import { slowBrainSelectedTiles } from './mocks';

export const getSlowBrainTileGroups = async (): Promise<TileGroup[]> => {
	const { data } = await httpClient.get<TileGroup[]>('/flows/slow_brain/tile-groups');
	return data;
};

export const getSlowBrainTileGroupSelectedTiles = async (): Promise<TileGroupSelected[]> => {
	const { data } = await httpClient.get<TileGroupSelected[]>('/tile-groups', {
		params: { flow: 'slow_brain' },
	});
	return data;
};

export const pickSlowBrainTiles = async (pickedTiles: TileSelectedPayload[]): Promise<void> => {
	const { data } = await httpClient.post<void>('/tile-groups/pick-tiles', pickedTiles);
	return data;
};

export const getSlowBrainPickedTiles = async (): Promise<SlowBrainTileSelected[]> => {
	const { data } = await httpClient.get<SlowBrainTileSelected[]>('/picked-tiles');
	return data;
};

export const mockGetSlowBrainPickedTiles = async (): Promise<SlowBrainTileSelected[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(slowBrainSelectedTiles), 500);
	});
