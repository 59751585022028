import { forwardRef, ReactElement } from 'react';
import { CheckboxInput } from '@/components/ui/inputs';
import QuestionAbstract, { QuestionAbstractProps } from './QuestionAbstract';

export interface QuestionMultipleChoiceProps extends Omit<QuestionAbstractProps, 'children'> {
	answers: Array<string>;
	onChange: (questionId: string, answer: string) => void;
}

export const QuestionMultipleChoice = forwardRef<HTMLDivElement, QuestionMultipleChoiceProps>(
	({ question, answers, onChange, ...questionAbstractProps }, ref): ReactElement => (
		<QuestionAbstract {...{ ...questionAbstractProps, question }} ref={ref}>
			{question.choices?.map((choice, index) => (
				<CheckboxInput
					key={index}
					id={`${question.id}-question-${index}`}
					name={`${question.id}-question`}
					label={choice.text}
					value={choice.id}
					onChange={(answer) => onChange(question.id, answer)}
					checked={answers?.includes(choice.id)}
				/>
			))}
		</QuestionAbstract>
	)
);

QuestionMultipleChoice.displayName = 'Question Multiple Choice';

export default QuestionMultipleChoice;
