import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ConcurrentLogoWhite from '@/assets/logos/logo-white-lg.png';
import ConcurrentLogoBlack from '@/assets/logos/logo-black-lg.png';
import { classNames, getClientId } from '@/lib/utils';
import { useUserInfo } from '@/queries/userQueries';

interface HeaderPageProps {
	theme: 'light' | 'dark';
	controls?: ReactNode;
}

const HeaderPage: FunctionComponent<HeaderPageProps> = ({ theme, controls }): ReactElement => {
	const clientId = getClientId();
	const { data: userInfo } = useUserInfo({ enabled: true, data: clientId });
	return (
		<header className="px-[60px] py-[24px]">
			<div className="container flex flex-row items-center justify-between gap-8">
				<Link to={`/${clientId}`} className="inline-block">
					<img
						src={theme === 'light' ? ConcurrentLogoBlack : ConcurrentLogoWhite}
						className="h-16"
						alt="Concurrent Dialogs logo"
					/>
				</Link>
				<div className="flex items-center">
					{userInfo && (
						<div
							className={classNames(
								controls ? 'mr-6' : '',
								'text-label-m',
								'font-medium',
								theme === 'light' ? 'text-body' : 'text-white'
							)}
						>
							{userInfo?.clientName}
						</div>
					)}
					{controls && <div className="flex flex-row items-center gap-4">{controls}</div>}
				</div>
			</div>
		</header>
	);
};

export default HeaderPage;
