import { Fragment, FunctionComponent, ReactElement } from 'react';
import { ProgressStepProps } from './progress-steps.types';
import ProgressStep from './ProgressStep';
import './progress-steps.scss';

interface ProgressStepsProps {
	steps: Array<ProgressStepProps>;
}

const ProgressSteps: FunctionComponent<ProgressStepsProps> = ({ steps }): ReactElement => (
	<div className="progress-steps-container">
		<div className="progress-steps-content">
			{steps.map((step, index) => (
				<Fragment key={index}>
					<ProgressStep {...step} />
					{index !== steps.length - 1 && <div className="progress-steps-divider" />}
				</Fragment>
			))}
		</div>
	</div>
);

export default ProgressSteps;
