import { RouteObject } from 'react-router-dom';
import { queryClient } from '@/queries';
import SlowBrainTileQuestionsIntro from '@/views/pages/slow-brain/tile-questions/intro';
import SlowBrainTileQuestions from '@/views/pages/slow-brain/tile-questions/index';
import SlowBrainOutput from '@/views/pages/slow-brain/output';
import SlowBrainTileSelectionIntro from '@/views/pages/slow-brain/tile-selection/intro';
import SlowBrainTileSelection from '@/views/pages/slow-brain/tile-selection/index';
import { tileQuestionsLoader, tileSelectionLoader } from './loaders';

export const slowBrainRoutes: RouteObject[] = [
	{
		path: 'slow-brain/tile-selection/intro',
		element: <SlowBrainTileSelectionIntro />,
	},
	{
		path: 'slow-brain/tile-selection',
		element: <SlowBrainTileSelection />,
		loader: ({ params: { clientId } }) => tileSelectionLoader(queryClient, clientId as string),
	},
	{
		path: 'slow-brain/tile-questions/intro',
		element: <SlowBrainTileQuestionsIntro />,
	},
	{
		path: 'slow-brain/tile-questions',
		element: <SlowBrainTileQuestions />,
		loader: ({ params: { clientId } }) => tileQuestionsLoader(queryClient, clientId as string),
	},
	{
		path: 'slow-brain/output',
		element: <SlowBrainOutput />,
	},
];
