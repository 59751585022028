import { FunctionComponent } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from './routes';
import { getClientId } from '@/lib/utils';

const router = createBrowserRouter(routes);
const Router: FunctionComponent = () => {
	router.subscribe((state) => {
		const clientId = getClientId();

		if (state.location.pathname === '/') window.location.href = `/${clientId}`;
	});

	return <RouterProvider router={router} />;
};

export default Router;
