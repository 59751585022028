import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Footer from '@/components/ui/footer';
import PageLayout from '@/views/layouts/page-layout';
import LinkButton from '@/components/ui/link-button';
import { SuccessBadge } from '@/components/feature/badges';
import { FastBrainTile } from '@/components/ui/tile';
import OutputLoader from '@/components/feature/output-loader';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import { Print } from '@/assets/icons/Print';
import { useFastBrainTileGroupsSelectedTilesOptions } from '@/queries/fastBrainQueries';
import { getFastBrainOutput } from '@/api/questions/fast-brain';
import { Output } from '@/api/questions/types';
import StackingCards from '@/components/feature/stacking-cards';

const FastBrainOutput: FunctionComponent = (): ReactElement => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [outputData, setOutputData] = useState<Output>();

	const { mutate } = useMutation({
		mutationFn: () => getFastBrainOutput(),
		onSuccess: (data) => {
			setOutputData(data);
			document.documentElement.style.setProperty('--cards', `${data.sections.length}`);
			setIsFinished(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	const { data: tiles } = useQuery({
		...useFastBrainTileGroupsSelectedTilesOptions,
		select: (data) => data[0].tiles,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => mutate(), []);

	const printButton: ReactNode = useMemo(
		() =>
			!isLoading ? (
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			) : undefined,
		[isLoading]
	);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={printButton}
		>
			{isLoading ? (
				<OutputLoader completed={isFinished} />
			) : (
				<div className="m-auto flex max-w-[872px] flex-col gap-12 p-12">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-6">
							<SuccessBadge />
							<h1 className="text-results-title">Fast Brain</h1>
						</div>
						<p className="text-body-18-md">
							Thank you for working through your Fast Brain Exercise. Here are the recommended next
							steps to help you implement your investment strategy and stay on track with your
							financial goals:
						</p>
					</div>
					<div className="flex flex-col gap-4">
						<h3 className="mb-5 text-heading-24 text-body opacity-70">
							Here&apos;s an overview of your tile selection:
						</h3>
						<div className="flex flex-row flex-wrap gap-4 gap-x-8 px-[10px] pb-5">
							{tiles?.map(({ text }, index) => <FastBrainTile key={index} text={text} />)}
						</div>
					</div>
					<StackingCards sections={outputData?.sections} />
					<div className="inline self-end print:hidden">
						<LinkButton to="../slow-brain/tile-selection/intro" title="Continue">
							Continue
						</LinkButton>
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default FastBrainOutput;
