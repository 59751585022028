import { FunctionComponent, useEffect } from 'react';
import { Engine } from 'react-babylonjs';
import { useSignals } from '@preact/signals-react/runtime';
import PersonalOutcomesTileScene from '@/views/scenes/PersonalOutcomesTileScene';
import MoneyOutcomesTileScene from '@/views/scenes/MoneyOutcomesTileScene';
import PlanVulnerabilitiesTileScene from '@/views/scenes/PlanVulnerabilitiesTileScene';
import Top5TileScene from '@/views/scenes/Top5TileScene';
import { isSceneLoading } from '@/views/scenes/signals/tiles.signals';
import BabylonjsLoader from '@/components/feature/babylonjs-loader';
import { TileGroup, TileGroupSelected } from '@/api/tile-groups/types';

interface SlowBrainTileRendererProps {
	currentStep: string;
	tilesGroup: TileGroup[];
	selectedTilesGroup: TileGroupSelected[];
}

const SlowBrainTileRenderer: FunctionComponent<SlowBrainTileRendererProps> = ({
	currentStep,
	tilesGroup,
	selectedTilesGroup,
}) => {
	useSignals();
	useEffect(() => {
		isSceneLoading.value = true;
	}, [currentStep]);

	const renderScene = () => {
		switch (currentStep) {
			case 'personal_outcomes':
				return (
					<PersonalOutcomesTileScene
						tilesGroup={tilesGroup}
						selectedTilesGroup={selectedTilesGroup}
						key={currentStep}
					/>
				);
			case 'money_outcomes':
				return (
					<MoneyOutcomesTileScene
						tilesGroup={tilesGroup}
						selectedTilesGroup={selectedTilesGroup}
						key={currentStep}
					/>
				);
			case 'plan_vulnerabilities':
				return (
					<PlanVulnerabilitiesTileScene
						tilesGroup={tilesGroup}
						selectedTilesGroup={selectedTilesGroup}
						key={currentStep}
					/>
				);
			case 'top_5_selection':
				return <Top5TileScene key={currentStep} />;
			default:
				return null;
		}
	};

	return (
		<div className="relative h-full w-full">
			{isSceneLoading.value && <BabylonjsLoader />}
			<Engine antialias adaptToDeviceRatio canvasId="babylonjs-canvas" className="h-full w-full">
				{renderScene()}
			</Engine>
		</div>
	);
};

export default SlowBrainTileRenderer;
