import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const RiskAssessmentIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		heading="Risk Assessment Questionnaire"
		link={{ to: '../risk-assessment/questionnaire', text: 'Start Risk Assessment Questionnaire' }}
	>
		<div className="flex flex-col gap-6">
			<p>
				These sections will assess your readiness for risk, decision-making patterns, and emotional
				impact on your investment decisions. Your responses will help us tailor advice to your
				financial profile
			</p>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						1
					</div>
					<span className="text-body-18-md">General Investment Questions</span>
				</div>
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						2
					</div>
					<span className="text-body-18-md">Investment Behavior Questions</span>
				</div>
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						3
					</div>
					<span className="text-body-18-md">Emotional Risk Tolerance</span>
				</div>
			</div>
		</div>
	</IntroLayout>
);

export default RiskAssessmentIntro;
