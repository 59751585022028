import { useEffect, useState } from 'react';
import { Answer } from '@/api/answers/types';
import { Question } from '@/api/questions/types';
import { QuestionAnswer } from '@/shared/types/questions.types';

interface UseFastBrainQuestionsState {
	questionnaire: QuestionAnswer;
	currentQuestion: number;
	onQuestionChange: (questionNumber: number) => void;
	onQuestionAnswered: (questionId: Question['id'], answer: string) => void;
}

export const getCurrentQuestionIndex = (questions: Question[], answers: Answer[]): number => {
	const currentQuestionIndex = questions
		.sort((q1: Question, q2: Question) => q1.orderNo - q2.orderNo)
		.findIndex((q) => {
			const answer = answers.find((a) => a.question.id === q.id)?.answer;
			return !answer || !answer.answerText?.length;
		});

	const currentQuestion = currentQuestionIndex !== -1 ? currentQuestionIndex : questions.length - 1;

	return currentQuestion;
};

export const useFastBrainQuestionsState = (
	questions: Question[],
	answers: Answer[]
): UseFastBrainQuestionsState => {
	const [questionnaire, setQuestionnaire] = useState<QuestionAnswer>({});
	const [currentQuestion, setCurrentQuestion] = useState<number>(0);

	useEffect(() => {
		const questionnaire: QuestionAnswer = questions.reduce(
			(prev, curr) => ({
				...prev,
				[curr.id]: answers.find((a) => a.question.id === curr.id)?.answer?.answerText || '',
			}),
			{}
		);

		setCurrentQuestion(0);
		setQuestionnaire(questionnaire);
	}, [questions, answers]);

	const onQuestionChange = (questionNumber: number) => setCurrentQuestion(questionNumber);
	const onQuestionAnswered = (questionId: Question['id'], answer: string) =>
		setQuestionnaire({ ...questionnaire, [questionId]: answer });

	return {
		questionnaire,
		currentQuestion,
		onQuestionChange,
		onQuestionAnswered,
	};
};
