export const ArrowRightRegular = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.6531 12.8154C21.8734 12.6044 22 12.3091 22 11.9998C22 11.6904 21.8734 11.3998 21.6531 11.1841L13.4031 3.30913C12.9531 2.87788 12.2406 2.89663 11.8141 3.34663C11.3875 3.79663 11.4016 4.50913 11.8516 4.93569L18.0672 10.8748H2.125C1.50156 10.8748 1 11.3763 1 11.9998C1 12.6232 1.50156 13.1248 2.125 13.1248H18.0672L11.8469 19.0591C11.3969 19.4904 11.3828 20.1982 11.8094 20.6482C12.2359 21.0982 12.9484 21.1123 13.3984 20.6857L21.6484 12.8107L21.6531 12.8154Z"
			fill="currentColor"
		/>
	</svg>
);
