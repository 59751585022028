import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import OutputLoader from '@/components/feature/output-loader';
import { ListBadge } from '@/components/feature/badges';
import Footer from '@/components/ui/footer';
import LinkButton from '@/components/ui/link-button';
import PageLayout from '@/views/layouts/page-layout';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import { Print } from '@/assets/icons/Print';
import { getNextBestActionsOutput } from '@/api/questions/portfolio-recommendations';
import { Output } from '@/api/questions/types';
import StackingCards from '@/components/feature/stacking-cards';

const NextBestActions: FunctionComponent = (): ReactElement => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [outputData, setOutputData] = useState<Output>();

	const { mutate } = useMutation({
		mutationFn: () => getNextBestActionsOutput(),
		onSuccess: (data) => {
			setOutputData(data);
			document.documentElement.style.setProperty('--cards', `${data.sections.length}`);
			setIsFinished(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => mutate(), []);

	const printButton: ReactNode = useMemo(
		() =>
			!isLoading ? (
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			) : undefined,
		[isLoading]
	);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={printButton}
		>
			{isLoading ? (
				<OutputLoader completed={isFinished} />
			) : (
				<div className="m-auto flex max-w-[872px] flex-col gap-12 p-12">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-6 print:flex-row print:items-center print:justify-start">
							<ListBadge />
							<h1 className="text-results-title">Next Best Actions</h1>
						</div>
						<p className="text-body-18-md">
							Thank you for working through your portfolio assessment. Here are the recommended next
							steps to help you implement your investment strategy and stay on track with your
							financial goals:
						</p>
					</div>
					<StackingCards sections={outputData?.sections} />
					<div className="inline self-end print:hidden">
						<LinkButton to="../portfolio-recommendations/client-report" title="Continue">
							Continue
						</LinkButton>
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default NextBestActions;
