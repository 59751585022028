export const Print = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_3342_91739)">
			<path
				d="M5.25 7.5V3C5.25 2.5875 5.5875 2.25 6 2.25H16.7578C16.9547 2.25 17.1469 2.32969 17.2875 2.47031L18.5297 3.7125C18.6703 3.85312 18.75 4.04531 18.75 4.24219V7.5H21V4.24219C21 3.44531 20.6859 2.68125 20.1234 2.11875L18.8766 0.876563C18.3141 0.314063 17.5547 0 16.7578 0H6C4.34531 0 3 1.34531 3 3V7.5H5.25ZM6 17.25H18V21.75H6V17.25ZM5.25 15C4.42031 15 3.75 15.6703 3.75 16.5H2.25V12C2.25 11.5875 2.5875 11.25 3 11.25H21C21.4125 11.25 21.75 11.5875 21.75 12V16.5H20.25C20.25 15.6703 19.5797 15 18.75 15H5.25ZM20.25 18.75H22.5C23.3297 18.75 24 18.0797 24 17.25V12C24 10.3453 22.6547 9 21 9H3C1.34531 9 0 10.3453 0 12V17.25C0 18.0797 0.670312 18.75 1.5 18.75H3.75V22.5C3.75 23.3297 4.42031 24 5.25 24H18.75C19.5797 24 20.25 23.3297 20.25 22.5V18.75Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3342_91739">
				<rect width={width} height={height} fill="white" />
			</clipPath>
		</defs>
	</svg>
);
