import { FunctionComponent, ReactElement } from 'react';
import './loading-spinner.scss';

interface LoadingSpinnerProps {
	completed?: boolean;
}

export const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
	completed,
}): ReactElement => (
	<div className="loading-spinner-wrapper">
		{!completed ? (
			<div className="loading-spinner" />
		) : (
			<svg
				className="loading-spinner-completed"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 130.2 130.2"
			>
				<circle
					className="path circle"
					fill="none"
					strokeWidth="8"
					strokeMiterlimit="10"
					cx="65.1"
					cy="65.1"
					r="58.1"
				/>
				<polyline
					className="path check"
					fill="none"
					strokeWidth="8"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					points="100.2,40.2 51.5,88.8 29.8,67.5 "
				/>
			</svg>
		)}
	</div>
);
