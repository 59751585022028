/* eslint-disable no-param-reassign, react/no-unknown-property */
import { useRef } from 'react';
import '@babylonjs/loaders/glTF/2.0';
import type { SceneEventArgs } from 'react-babylonjs';
import { Scene } from 'react-babylonjs';
import { ActionManager, Color3, Vector3, ExecuteCodeAction, MeshBuilder } from '@babylonjs/core';
import type { AbstractMesh, Scene as BabylonScene, FreeCamera } from '@babylonjs/core';

import {
	addTextToTile,
	categorizeSelectedTiles,
	categorizeTiles,
	createHoverAnimation,
	createShadowGenerator,
	createStackingAnimation,
	findCategoryForSelectedTile,
	findCategoryForTile,
	forceEndFrameAnimation,
	getFirstAvailableSlot,
	loadAndProcessScene,
	processMaterials,
	resetQuaterinionRotation,
	setMaterialsPerCategory,
	setupSpotlight,
	showInspector,
	totalBoundingInfo,
	updateCameraFovPerDominos,
	updateStackingAnimationPosition,
	updateStackingAnimationStartPositionAndRotation,
	updateTemporaryParentZOffset,
} from '@/views/scenes/utils/tiles.utils';

import { InternalTileData, TileData, LoadResult } from '@/views/scenes/types/tiles.types';
import { TileSelected } from '@/api/tile-groups/types';

import {
	isSceneLoading,
	slowBrainTileGroupsTop5,
	slowBrainTop5SelectedTiles,
	slowBrainTop5SlotsData,
	slowBrainTop5TilesData,
	updateSlotData,
	updateTileHoverAnimation,
	updateTileIsSelected,
	updateTileStackingAnimation,
} from '@/views/scenes/signals/tiles.signals';

export const Top5TileScene = () => {
	const sceneRef = useRef<BabylonScene>();
	const canvasRef = useRef<HTMLCanvasElement>();
	const cameraRef = useRef<FreeCamera>();
	const isInteractionReady = useRef<boolean>(false);
	const sceneUrl = '/models/slowBrain_Top5.glb';
	const isInspectorOn = import.meta.env.VITE_ENVIRONMENT === 'local';

	const categorizedTiles = slowBrainTileGroupsTop5.value
		? categorizeTiles(slowBrainTileGroupsTop5.value)
		: [];
	const allTiles = categorizedTiles ? categorizedTiles.flatMap((t) => t.tiles) : [];

	const categorizedTilesSelected = slowBrainTop5SelectedTiles.value
		? categorizeSelectedTiles(slowBrainTop5SelectedTiles.value)
		: [];
	const allSelectedTiles = categorizedTilesSelected
		? categorizedTilesSelected.flatMap((t) => t.tiles)
		: [];

	const isInitialLoad = useRef<boolean>(!(allSelectedTiles && allSelectedTiles.length > 0));

	const restoreSelectedTileToSlot = (
		tile: InternalTileData,
		selectedTiles: TileSelected[],
		animationStartTransform: [Vector3, number],
		scene: BabylonScene
	) => {
		const slots = slowBrainTop5SlotsData.value;
		const tiles = slowBrainTop5TilesData.value;

		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		const targetMesh = targetTile.mesh;
		targetMesh.isPickable = false;
		const selectedTile = selectedTiles.filter(
			(selectedTile) => selectedTile.slot.targetTile === tile.name
		)[0];
		const selectedTileSlotId = selectedTile.slot.slotId;
		const targetSlot = slots.filter((slot) => slot.id === selectedTileSlotId)[0];

		const tileData: TileData = {
			tag: targetTile.tileTag,
			id: targetTile.name,
			text: targetTile.tileText,
		};

		updateSlotData(slowBrainTop5SlotsData, targetSlot.id, true, tileData);

		let { stackingAnimation } = targetTile;
		if (!stackingAnimation) {
			stackingAnimation = createStackingAnimation(
				targetTile,
				new Vector3(...targetSlot.position),
				scene
			);
			updateTileStackingAnimation(slowBrainTop5TilesData, targetTile.name, stackingAnimation);
		}
		resetQuaterinionRotation(targetTile);
		updateStackingAnimationStartPositionAndRotation(
			stackingAnimation,
			animationStartTransform[0],
			animationStartTransform[1]
		);
		forceEndFrameAnimation(stackingAnimation);
		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;
		});
	};

	const addTileToFirstAvailableSlot = (tile: InternalTileData, scene: BabylonScene) => {
		const slots = slowBrainTop5SlotsData.value;
		const tiles = slowBrainTop5TilesData.value;
		const targetSlot = getFirstAvailableSlot(slots);
		if (!targetSlot) return;
		const slotPosition = new Vector3(
			targetSlot.position[0],
			targetSlot.position[1],
			targetSlot.position[2]
		);

		const tileData: TileData = {
			tag: tile.tileTag,
			id: tile.name,
			text: tile.tileText,
		};

		updateSlotData(slowBrainTop5SlotsData, targetSlot.id, true, tileData);
		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		targetTile.mesh.isPickable = false;
		targetTile.mesh.computeWorldMatrix(true);
		let { stackingAnimation } = targetTile;
		if (!stackingAnimation) {
			stackingAnimation = createStackingAnimation(targetTile, slotPosition, scene);
			updateTileStackingAnimation(slowBrainTop5TilesData, targetTile.name, stackingAnimation);
		}
		stackingAnimation.stop();
		updateStackingAnimationPosition(stackingAnimation, slotPosition);
		stackingAnimation.start(false, 1, stackingAnimation.from, stackingAnimation.to);
		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;
		});
	};

	const removeTileFromSlot = (tile: InternalTileData) => {
		const slots = slowBrainTop5SlotsData.value;
		const tiles = slowBrainTop5TilesData.value;
		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		const targetSlot = slots.find((slot) => slot.tile?.id === targetTile.name);
		targetTile.mesh.isPickable = false;
		if (!targetSlot) return;
		updateSlotData(slowBrainTop5SlotsData, targetSlot.id, false, null);
		const { stackingAnimation } = targetTile;
		if (!stackingAnimation) return;
		stackingAnimation.stop();
		stackingAnimation.start(false, 1, stackingAnimation.to, stackingAnimation.from);

		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;

			const { hoverAnimation } = targetTile;
			if (hoverAnimation) {
				const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.to;
				hoverAnimation.stop();
				hoverAnimation.start(false, 5, targetStart, hoverAnimation.from);
			}
		});
	};

	const handleTilePick = (tile: InternalTileData) => {
		if (!isInteractionReady.current) return;
		const scene = sceneRef.current;
		if (!scene) return;

		const data = slowBrainTop5TilesData.value;
		const slots = slowBrainTop5SlotsData.value;
		const test = slots.filter((slot) => !slot.isOccupied);

		const selectedTile = data.filter((t) => t.name === tile.name);

		if (!selectedTile[0].isSelected) {
			if (test.length > 0) {
				updateTileIsSelected(slowBrainTop5TilesData, selectedTile[0].name, true);
				addTileToFirstAvailableSlot(selectedTile[0], scene);
			}
		} else {
			updateTileIsSelected(slowBrainTop5TilesData, selectedTile[0].name, false);
			removeTileFromSlot(selectedTile[0]);
		}
	};

	const handleTileOver = (tile: InternalTileData, scene: BabylonScene) => {
		if (!isInteractionReady.current) return;

		const targetTile = slowBrainTop5TilesData.value.filter((t) => t.name === tile.name)[0];
		if (targetTile.isSelected) return;
		if (targetTile.stackingAnimation?.isPlaying) return;
		let { hoverAnimation } = targetTile;
		if (!hoverAnimation) {
			hoverAnimation = createHoverAnimation(targetTile, scene);
			updateTileHoverAnimation(slowBrainTop5TilesData, targetTile.name, hoverAnimation);
		}
		const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.from;
		hoverAnimation.stop();
		hoverAnimation.start(false, 3, targetStart, hoverAnimation.to);
	};

	const handleTileOut = (tile: InternalTileData) => {
		if (!isInteractionReady.current) return;
		const targetTile = slowBrainTop5TilesData.value.filter((t) => t.name === tile.name)[0];
		if (targetTile.isSelected) return;
		if (targetTile.stackingAnimation?.isPlaying) return;

		const { hoverAnimation } = targetTile;
		if (!hoverAnimation) return;
		const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.to;
		hoverAnimation.stop();
		hoverAnimation.start(false, 4, targetStart, hoverAnimation.from);
	};

	const setupScene = ({ scene }: SceneEventArgs) => {
		sceneRef.current = scene;
		canvasRef.current = scene.getEngine().getRenderingCanvas() as HTMLCanvasElement;

		loadAndProcessScene(sceneUrl, allTiles, allSelectedTiles, scene)
			.then(({ tiles, slots }: LoadResult<InternalTileData>) => {
				const filteredSlots = slots.filter((slot) => slot.id <= 5);
				slowBrainTop5TilesData.value = tiles;
				slowBrainTop5SlotsData.value = filteredSlots;

				const spotlight = setupSpotlight(scene);
				const shadowGenerator = createShadowGenerator(spotlight);
				// calculate bounding of all tiles and add them as child to temporaryParent
				// .. which then can be moved top or bottom to give offset to the tile position within the camera frame

				processMaterials(scene.materials);

				let temporaryParent: AbstractMesh | null = null;
				if (tiles.length > 0) {
					const tilesBoundingInfo = totalBoundingInfo(tiles);
					const tilesTotalSize = tilesBoundingInfo.boundingBox.extendSize.scale(2);
					const tilesWidth = tilesTotalSize.x;
					const tilesDepth = tilesTotalSize.z;
					const tilesCenter = tilesBoundingInfo.boundingBox.center;

					temporaryParent = MeshBuilder.CreateBox(
						'temporaryParent',
						{ width: tilesWidth, depth: tilesDepth, height: 0.1 },
						scene
					);
					temporaryParent.isVisible = false;
					temporaryParent.position = tilesCenter.clone();
				}

				tiles.forEach((tile: InternalTileData) => {
					shadowGenerator.addShadowCaster(tile.mesh);
					const tileCategory = findCategoryForTile(tile.tileTag, categorizedTiles);
					const selectedTileCategory = findCategoryForSelectedTile(
						tile.tileTag,
						categorizedTilesSelected
					);
					// tiles and selected in this scene by default doesnt have any material
					// their material is defined depending on the category they come from
					if (tileCategory) setMaterialsPerCategory(tileCategory, tile, scene);
					if (selectedTileCategory) setMaterialsPerCategory(selectedTileCategory, tile, scene);

					const targetTile = slowBrainTop5TilesData.value.filter((t) => t.name === tile.name)[0];

					if (!targetTile.isSelected && tiles.length > 0) {
						targetTile.mesh.setParent(temporaryParent);
					}

					targetTile.mesh.actionManager = new ActionManager(scene);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPickTrigger, () => handleTilePick(tile))
					);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () =>
							handleTileOver(tile, scene)
						)
					);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => handleTileOut(tile))
					);

					const targetMaterialName = targetTile.mesh.material?.name || null;
					addTextToTile(targetTile, targetMaterialName);
				});

				if (temporaryParent) {
					// set Z position of the parent
					updateTemporaryParentZOffset(temporaryParent, allTiles.length);
					// after positioning, set dominos parent to ROOT object again (which is default parent)
					temporaryParent.getChildren().forEach((child) => {
						const c = child as AbstractMesh;
						c.setParent(scene.meshes[0]);
					});
					// remove this temporary object from scene after proper positioning
					temporaryParent.dispose();
				}

				if (!isInitialLoad.current) {
					tiles.forEach(() => {
						const selected = slowBrainTop5TilesData.value.filter((tile) => tile.isSelected);
						selected.forEach((tile) => {
							const animStartPosition = tile.mesh.position;
							const animStartRotationY = tile.mesh.rotation.y;
							restoreSelectedTileToSlot(
								tile,
								allSelectedTiles,
								[animStartPosition, animStartRotationY],
								scene
							);
						});
					});
				}
			})
			.catch((error) => {
				console.error('An error occurred while loading and processing the scene:', error);
			});

		scene.onReadyObservable.add(() => {
			const camera = scene.cameras.find((c) => c.getClassName() === 'FreeCamera');
			if (camera) {
				cameraRef.current = camera as FreeCamera;
				scene.activeCamera = camera;

				// update camera fov depending on the number of dominos in the scene
				updateCameraFovPerDominos(camera as FreeCamera, allTiles.length);
			}
			isInteractionReady.current = true;
			isSceneLoading.value = false;

			if (isInspectorOn) showInspector(scene);
		});
	};

	return (
		<Scene
			onSceneMount={(scene: SceneEventArgs) => {
				setupScene(scene);
			}}
		>
			<arcRotateCamera
				name="debugCamera"
				alpha={Math.PI / 2}
				beta={0.3}
				radius={15}
				target={new Vector3(0.8, 0, -3)}
				minZ={0}
				wheelPrecision={20}
			/>
			<hemisphericLight
				name="ambientLight"
				direction={new Vector3(0, 1, 0)}
				specular={new Color3(0, 0, 0)}
				intensity={0.8}
			/>
		</Scene>
	);
};

export default Top5TileScene;
