import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const SlowBrainTileSelectionIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Selection"
		heading="Slow Brain"
		link={{ to: '../slow-brain/tile-selection', text: 'Start Tile Selection' }}
	>
		<div className="flex flex-col gap-6">
			<p>
				This part of the journey encourages you to reflect on your goals, assess your financial
				standing, and understand your areas of risk. In the Slow Brain section of Concurrent
				Dialogues, we focus on three critical aspects of self-discovery:
			</p>
			<div className="flex flex-col gap-4">
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						1
					</div>
					<span className="text-body-18-md">Personal Outcomes</span>
				</div>
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						2
					</div>
					<span className="text-body-18-md">Money Outcomes</span>
				</div>
				<div className="flex flex-row gap-2">
					<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
						3
					</div>
					<span className="text-body-18-md">Plan Vulnerabilities</span>
				</div>
			</div>
		</div>
	</IntroLayout>
);

export default SlowBrainTileSelectionIntro;
