import { FunctionComponent, ReactElement } from 'react';
import { classNames } from '@/lib/utils';
import '@/scss/tile.scss';

export interface TileProps {
	text: string;
	classes?: string[];
	imgSrc?: string;
}

export const Tile: FunctionComponent<TileProps> = ({
	text,
	classes = [],
	imgSrc,
}): ReactElement => (
	<div
		className={classNames(
			'relative',
			'h-[74px]',
			'w-[145px]',
			'flex',
			'shrink-0',
			'items-center',
			'justify-center',
			'py-3',
			'rounded-md',
			...classes
		)}
	>
		<img src={imgSrc} alt="Tile" className="absolute inset-0 h-full w-full opacity-30" />
		<p className="relative text-center text-button-s text-blue-dark">{text}</p>
	</div>
);
