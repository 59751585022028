import { QueryClient } from '@tanstack/react-query';
import { redirect } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Answer } from '@/api/answers/types';
import { Question } from '@/api/questions/types';
import { TileGroup, TileGroupSelected } from '@/api/tile-groups/types';
import {
	useFastBrainTileGroupsOptions,
	useFastBrainTileGroupsSelectedTilesOptions,
	useFastBrainQuestionsOptions,
	useFastBrainAnswersOptions,
} from '@/queries/fastBrainQueries';
import { CLIENT_ID_KEY } from '@/lib/utils';

export interface FastBrainTileSelectionLoaderResult {
	tileGroups: TileGroup[];
	tileGroupsSelectedTiles: TileGroupSelected[];
}

export const tileSelectionLoader = async (
	queryClient: QueryClient,
	clientId: string
): Promise<FastBrainTileSelectionLoaderResult | Response> => {
	localStorage.setItem(CLIENT_ID_KEY as string, clientId);

	try {
		const [tileGroups, tileGroupsSelectedTiles] = await Promise.all([
			queryClient.fetchQuery(useFastBrainTileGroupsOptions),
			queryClient.fetchQuery(useFastBrainTileGroupsSelectedTilesOptions),
		]);

		return { tileGroups, tileGroupsSelectedTiles };
	} catch (error) {
		if ((error as AxiosError).response?.status === 401) {
			return redirect('/unauthorized');
		}

		throw error;
	}
};

export interface FastBrainTileQuestionsLoaderResult {
	tileGroupSelectedTiles: TileGroupSelected[];
	questions: Question[];
	answers: Answer[];
}

export const tileQuestionsLoader = async (
	queryClient: QueryClient,
	clientId: string
): Promise<FastBrainTileQuestionsLoaderResult | Response> => {
	localStorage.setItem(CLIENT_ID_KEY as string, clientId);

	try {
		const [tileGroupSelectedTiles, questions, answers] = await Promise.all([
			queryClient.fetchQuery(useFastBrainTileGroupsSelectedTilesOptions),
			queryClient.fetchQuery(useFastBrainQuestionsOptions),
			queryClient.fetchQuery(useFastBrainAnswersOptions),
		]);

		return { tileGroupSelectedTiles, questions, answers };
	} catch (error) {
		if ((error as AxiosError).response?.status === 401) {
			return redirect('/unauthorized');
		}

		throw error;
	}
};
