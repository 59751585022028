import { useQuery } from '@tanstack/react-query';
import { QueryProps } from './types.queries';
import { getAuthUser } from '@/api/users';

export const userKeys = {
	user: ['user_info'],
};

// Query Options
export const useUserInfoOptions = ({ enabled = false, data }: QueryProps<string>) => ({
	queryKey: userKeys.user,
	queryFn: () => getAuthUser(data as string),
	enabled,
});

// Queries
export const useUserInfo = (props: QueryProps<string>) => useQuery(useUserInfoOptions(props));
