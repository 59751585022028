import { FunctionComponent, ReactElement, ReactNode } from 'react';
import { classNames } from '@/lib/utils';
import HeaderPage from '@/components/ui/header/header-page';

interface PageLayoutProps {
	theme?: 'light' | 'dark';
	children: ReactNode;
	headerControls?: ReactNode;
	footer?: ReactNode;
	centerContent?: boolean;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
	theme = 'light',
	children,
	headerControls,
	footer,
	centerContent,
}): ReactElement => (
	<div
		className={classNames(
			'flex',
			'min-h-[100%]',
			'flex-col',
			...(theme === 'light' ? ['bg-neutral-page', 'text-black'] : ['bg-blue-dark', 'text-white'])
		)}
	>
		<HeaderPage theme={theme} controls={headerControls} />
		<main
			className={classNames(
				'container',
				'flex-1',
				...(centerContent ? ['flex', 'items-center', 'justify-center'] : [])
			)}
		>
			{children}
		</main>
		{footer}
	</div>
);

export default PageLayout;
