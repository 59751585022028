import { FunctionComponent, ReactElement, useEffect, useRef, createRef, useState } from 'react';
import QuestionGroup, { QuestionGroupProps } from '../question-group/QuestionGroup';
import { classNames } from '@/lib/utils';

type QuestionGroup = Omit<QuestionGroupProps, 'scrollElementRef'>;

export interface QuestionGroupPanel {
	id: string;
	questionGroup: QuestionGroup;
}

interface QuestionGroupPanelsProps {
	panels: QuestionGroupPanel[];
	currentPanelId: QuestionGroupPanel['id'];
}

type QuestionGroupPanelRefs = Record<QuestionGroupPanel['id'], HTMLDivElement>;
type QuestionScrollElementRefs = Record<
	QuestionGroupPanel['id'],
	React.MutableRefObject<HTMLDivElement | null>
>;

const QuestionGroupPanels: FunctionComponent<QuestionGroupPanelsProps> = ({
	panels,
	currentPanelId,
}): ReactElement => {
	const wrapperEl = useRef<HTMLDivElement>(null);
	const questionGroupPanelRefs = useRef<QuestionGroupPanelRefs>({});
	const questionScrollElementRefs = useRef<QuestionScrollElementRefs>(
		panels.reduce((prev, curr) => ({ ...prev, [curr.id]: createRef<HTMLDivElement>() }), {})
	);

	const [horizontalTransitionEnded, setHorizontalTransitionEnded] = useState<boolean>(false);

	useEffect(() => {
		const currentPanel = questionGroupPanelRefs.current[currentPanelId];

		const checkIfHorizontalTransitionHasEnded = (
			entries: IntersectionObserverEntry[],
			observer: { disconnect: () => void }
		) => {
			setHorizontalTransitionEnded(false);
			entries.forEach((entry: { target: Element; intersectionRatio: number }) => {
				if (entry.target === currentPanel && entry.intersectionRatio > 0.9) {
					// The element is now fully visible
					setHorizontalTransitionEnded(true);

					// Stop listening for intersection changes
					observer.disconnect();
				}
			});
		};

		const observer = new IntersectionObserver(checkIfHorizontalTransitionHasEnded, {
			root: null,
			threshold: 0.9,
		});

		if (currentPanel) observer.observe(currentPanel);

		const idx = panels.findIndex((pn) => pn.id === currentPanelId);
		if (wrapperEl.current) wrapperEl.current.style.transform = `translate3d(-${idx * 100}vw, 0, 0)`;
	}, [currentPanelId, horizontalTransitionEnded, panels]);

	return (
		<div
			className="flex h-full"
			ref={wrapperEl}
			style={{
				transform: 'translate3d(0, 0, 0)`',
				WebkitTransition: 'transform 0.6s',
			}}
		>
			{panels.map(({ id, questionGroup }) => (
				<div
					key={id}
					id={id}
					className="h-full flex-shrink-0 flex-grow basis-[100vw]"
					// eslint-disable-next-line no-return-assign
					ref={(element: HTMLDivElement) => (questionGroupPanelRefs.current[id] = element)}
				>
					<div
						className={classNames(
							'snap-y',
							'snap-mandatory',
							'scroll-smooth',
							'h-full',
							horizontalTransitionEnded ? 'overflow-y-scroll' : 'overflow-y-hidden'
						)}
						ref={questionScrollElementRefs.current[id]}
					>
						<QuestionGroup
							{...questionGroup}
							belongsToActivePanel={id === currentPanelId}
							scrollElementRef={questionScrollElementRefs.current[id]}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export default QuestionGroupPanels;
