import { useMemo } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';
import {
	isSceneLoading,
	isSlowBrainMoneyDisabled,
	isSlowBrainPersonalDisabled,
	isSlowBrainTop5Disabled,
	isSlowBrainVulnerabilitiesDisabled,
} from '@/views/scenes/signals/tiles.signals';

interface UseIsSlowBrainTileSelectionDisabledProps {
	currentStep: SlowBrainTileSelectionStep;
	isLoading: boolean;
}

export const useIsSlowBrainTileSelectionDisabled = ({
	currentStep,
	isLoading,
}: UseIsSlowBrainTileSelectionDisabledProps) => {
	useSignals();
	return useMemo(() => {
		if (isLoading || isSceneLoading.value) return true;
		if (currentStep === 'personal_outcomes') return isSlowBrainPersonalDisabled.value;
		if (currentStep === 'money_outcomes') return isSlowBrainMoneyDisabled.value;
		if (currentStep === 'plan_vulnerabilities') return isSlowBrainVulnerabilitiesDisabled.value;
		return isSlowBrainTop5Disabled.value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentStep,
		isLoading,
		isSlowBrainPersonalDisabled.value,
		isSlowBrainMoneyDisabled.value,
		isSlowBrainVulnerabilitiesDisabled.value,
		isSlowBrainTop5Disabled.value,
		isSceneLoading.value,
	]);
};
