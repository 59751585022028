import { FunctionComponent, ReactNode, useEffect } from 'react';
import { motion as m } from 'framer-motion';
import './modal.scss';

export interface ModalProps {
	open: boolean;
	onClose: () => void;
	keyName?: string;
	children: ReactNode;
}

const Modal: FunctionComponent<ModalProps> = ({ open, onClose, keyName, children }) => {
	const modalVariants = {
		open: { opacity: 1, y: 0, transition: { duration: 0.5 } },
		closed: { opacity: 0, y: -20 },
	};

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') onClose();
		};

		window.addEventListener('keydown', handleEscape);
		return () => {
			window.removeEventListener('keydown', handleEscape);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		open && (
			<div className="modal">
				<m.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 0.7, transition: { duration: 0.4 } }}
					className="modal-overlay"
					onClick={() => onClose()}
				></m.div>
				<m.div
					key={keyName}
					initial="closed"
					animate="open"
					exit="closed"
					variants={modalVariants}
					className="modal-wrapper"
				>
					{children}
				</m.div>
			</div>
		)
	);
};

export default Modal;
