export const UserSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.5 12C13.0913 12 14.6174 11.3679 15.7426 10.2426C16.8679 9.11742 17.5 7.5913 17.5 6C17.5 4.4087 16.8679 2.88258 15.7426 1.75736C14.6174 0.632141 13.0913 0 11.5 0C9.9087 0 8.38258 0.632141 7.25736 1.75736C6.13214 2.88258 5.5 4.4087 5.5 6C5.5 7.5913 6.13214 9.11742 7.25736 10.2426C8.38258 11.3679 9.9087 12 11.5 12ZM9.35781 14.25C4.74063 14.25 1 17.9906 1 22.6078C1 23.3766 1.62344 24 2.39219 24H20.6078C21.3766 24 22 23.3766 22 22.6078C22 17.9906 18.2594 14.25 13.6422 14.25H9.35781Z"
			fill="currentColor"
		/>
	</svg>
);
