import { useQueries, useQueryClient } from '@tanstack/react-query';
import { TileGroup, TileGroupSelected } from '@/api/tile-groups/types';
import {
	useFastBrainTileGroupsOptions,
	useFastBrainTileGroupsSelectedTilesOptions,
} from '@/queries/fastBrainQueries';

interface UseFastBrainTileSelectionDataProps {
	tileGroupsInitialData: TileGroup[];
	tileGroupsSelectedTilesInitialData: TileGroupSelected[];
}

interface UseFastBrainTileSelectionData {
	tileGroups: TileGroup[];
	tileGroupsSelectedTiles: TileGroupSelected[];
	invalidateSelectedTiles: () => Promise<void>;
}

export const useFastBrainTileSelectionData = ({
	tileGroupsInitialData,
	tileGroupsSelectedTilesInitialData,
}: UseFastBrainTileSelectionDataProps): UseFastBrainTileSelectionData => {
	const queryClient = useQueryClient();
	const { queryKey: selectedTilesQueryKey } = useFastBrainTileGroupsSelectedTilesOptions;

	const [{ data: tileGroups }, { data: tileGroupsSelectedTiles }] = useQueries({
		queries: [
			{
				...useFastBrainTileGroupsOptions,
				initialData: tileGroupsInitialData,
				staleTime: Infinity,
			},
			{
				...useFastBrainTileGroupsSelectedTilesOptions,
				initialData: tileGroupsSelectedTilesInitialData,
				staleTime: Infinity,
			},
		],
	});

	const invalidateSelectedTiles = () =>
		queryClient.invalidateQueries({ queryKey: [...selectedTilesQueryKey] });

	return {
		tileGroups,
		tileGroupsSelectedTiles,
		invalidateSelectedTiles,
	};
};
