import { Question, QuestionType } from '../types';

export const riskAssessmentGeneralInvestment: Question[] = [
	{
		id: '550e8400-e29b-41d4-a716-446655440000',
		type: QuestionType.SINGLE_CHOICE,
		flow: 'risk_assessment',
		category: 'General Investment Questions',
		tile: 'General Investment Questions',
		tag: 'general_investment',
		orderNo: 1,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544001',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'risk_assessment',
		category: 'General Investment Questions',
		tile: 'General Investment',
		tag: 'general_investment',
		orderNo: 2,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'I am not concerned with a loss, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Provided the loss is less than 20%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Provided the loss is less than 10%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Provided the loss is less than 5%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'I am not comfortable with losses and prefer predictable returns',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'Which of these statements best describes your attitude about the next five years performance of your investments?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544002',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'risk_assessment',
		category: 'General Investment Questions',
		tile: 'General Investment Questions',
		tag: 'general_investment',
		orderNo: 3,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
];

export const riskAssessmentInvestmentBehavior: Question[] = [
	{
		id: '550e8400-e29b-41d4-a716-44665544003',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'risk_assessment',
		category: 'Investment Behavior Questions',
		tile: 'Investment Behavior Questions',
		tag: 'investment_behavior',
		orderNo: 1,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544004',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'risk_assessment',
		category: 'Investment Behavior Questions',
		tile: 'Investment Behavior',
		tag: 'investment_behavior',
		orderNo: 2,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'I am not concerned with a loss, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Provided the loss is less than 20%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Provided the loss is less than 10%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Provided the loss is less than 5%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'I am not comfortable with losses and prefer predictable returns',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'Which of these statements best describes your attitude about the next five years performance of your investments?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544005',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'risk_assessment',
		category: 'Investment Behavior Questions',
		tile: 'Investment Behavior',
		tag: 'investment_behavior',
		orderNo: 3,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
];

export const riskAssessmentEmotionalRiskTolerance: Question[] = [
	{
		id: '550e8400-e29b-41d4-a716-44665544006',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'emotional_risk_tolerance',
		category: 'Emotional Risk Tolerance',
		tile: 'Emotional Risk Tolerance',
		tag: 'emotional_risk_tolerance',
		orderNo: 1,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544007',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'emotional_risk_tolerance',
		category: 'Emotional Risk Tolerance',
		tile: 'Emotional Risk Tolerance',
		tag: 'emotional_risk_tolerance',
		orderNo: 2,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'I am not concerned with a loss, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Provided the loss is less than 20%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Provided the loss is less than 10%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Provided the loss is less than 5%, I believe I will make it back',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'I am not comfortable with losses and prefer predictable returns',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'Which of these statements best describes your attitude about the next five years performance of your investments?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
	{
		id: '550e8400-e29b-41d4-a716-44665544008',
		type: QuestionType.MULTIPLE_CHOICE,
		flow: 'emotional_risk_tolerance',
		category: 'Emotional Risk Tolerance',
		tile: 'Emotional Risk Tolerance',
		tag: 'emotional_risk_tolerance',
		orderNo: 3,
		choices: [
			{
				id: '550e8400-e29b-41d4-a716-446655440001',
				text: 'Current income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440002',
				text: 'Retirement Savings',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440003',
				text: 'Growth and Income',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440004',
				text: 'Growth',
			},
			{
				id: '550e8400-e29b-41d4-a716-446655440005',
				text: 'Speculate',
			},
		],
		questionMetadata: [
			{
				label: '',
				text: 'What is your primary purpose for investing?',
				subtext: '',
				hint: '',
				imageUrl: null,
			},
		],
	},
];

export const riskAssessmentQuestions = [
	...riskAssessmentGeneralInvestment,
	...riskAssessmentInvestmentBehavior,
	...riskAssessmentEmotionalRiskTolerance,
];
