import { FunctionComponent, ReactElement } from 'react';
import { classNames } from '@/lib/utils';
import { CheckSolid } from '@/assets/icons/CheckSolid';
import { ProgressStepProps, StepStatus } from './progress-steps.types';

const getStepTextColorByStatus = (status: StepStatus): string => {
	if (status === StepStatus.ACTIVE) return 'text-blue-dark';
	if (status === StepStatus.INACTIVE) return 'text-neutral-700';
	return 'text-default-black';
};

const getStepIconBorderColorByStatus = (status: StepStatus): string => {
	if (status === StepStatus.ACTIVE) return 'border-blue-primary';
	if (status === StepStatus.INACTIVE) return 'border-neutral-400';
	return 'border-completed';
};

const getStepIconTextColorByStatus = (status: StepStatus): string => {
	if (status === StepStatus.ACTIVE) return 'text-blue-dark';
	if (status === StepStatus.INACTIVE) return 'text-neutral-400';
	return 'text-completed';
};

const ProgressStep: FunctionComponent<ProgressStepProps> = ({
	status,
	serialNumber,
	name,
	onClick,
}): ReactElement => (
	<button
		className={classNames(
			'flex',
			'flex-row',
			'items-center',
			'justify-center',
			'gap-2',
			getStepTextColorByStatus(status)
		)}
		disabled={status === StepStatus.INACTIVE}
		onClick={onClick}
	>
		<div
			className={classNames(
				'flex',
				'items-center',
				'justify-center',
				'h-[28px]',
				'w-[28px]',
				'rounded-full',
				'border-[3px]',
				'text-button-s',
				getStepIconBorderColorByStatus(status),
				getStepIconTextColorByStatus(status)
			)}
		>
			{status === StepStatus.COMPLETED ? <CheckSolid width={14} height={14} /> : serialNumber}
		</div>
		<span className="text-label-m font-bold">{name}</span>
	</button>
);

export default ProgressStep;
