export enum StepStatus {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	COMPLETED = 'Completed',
}

export interface ProgressStepProps {
	status: StepStatus;
	serialNumber: number;
	name: string;
	onClick: () => void;
}
