import { useQuery } from '@tanstack/react-query';
import { getSlowBrainQuestions } from '@/api/questions/slow-brain';
import { getSlowBrainAnswers } from '@/api/answers/slow-brain';
import { QueryProps } from './types.queries';
import {
	getSlowBrainPickedTiles,
	getSlowBrainTileGroups,
	getSlowBrainTileGroupSelectedTiles,
} from '@/api/tile-groups/slow-brain';

export const slowBrainKeys = {
	questions: ['slow_brains_questions'],
	questionsTags: (...tags: string[]) => [...slowBrainKeys.questions, { tags }],
	answers: ['slow_brain_answers'],
	selectedTiles: ['slow_brain_selected_tiles'],
	tileGroups: ['slow_brain_tile_groups'],
	tileGroupsSelectedTiles: ['slow_brain_tile_groups_selected_tiles'],
};

// Options
export const useSlowBrainQuestionOptions = ({ enabled = false, data }: QueryProps<string[]>) => ({
	queryKey: slowBrainKeys.questionsTags(...(data as string[])),
	queryFn: () => getSlowBrainQuestions(...(data as string[])),
	enabled,
});

export const useSlowBrainPickedTilesOptions = {
	queryKey: slowBrainKeys.selectedTiles,
	queryFn: () => getSlowBrainPickedTiles(),
};

export const useSlowBrainAnswersOptions = {
	queryKey: slowBrainKeys.answers,
	queryFn: () => getSlowBrainAnswers(),
};

export const useSlowBrainTileGroupsOptions = {
	queryKey: slowBrainKeys.tileGroups,
	queryFn: () => getSlowBrainTileGroups(),
};

export const useSlowBrainTileGroupsSelectedTilesOptions = {
	queryKey: slowBrainKeys.tileGroupsSelectedTiles,
	queryFn: () => getSlowBrainTileGroupSelectedTiles(),
};

// Queries
export const useSlowBrainQuestion = (props: QueryProps<string[]>) =>
	useQuery(useSlowBrainQuestionOptions(props));

export const useSlowBrainPickedTiles = () => useQuery(useSlowBrainPickedTilesOptions);

export const useSlowBrainAnswers = () => useQuery(useSlowBrainAnswersOptions);
