export const ListUlSolid = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3 6.75C3.59674 6.75 4.16903 6.51295 4.59099 6.09099C5.01295 5.66903 5.25 5.09674 5.25 4.5C5.25 3.90326 5.01295 3.33097 4.59099 2.90901C4.16903 2.48705 3.59674 2.25 3 2.25C2.40326 2.25 1.83097 2.48705 1.40901 2.90901C0.987053 3.33097 0.75 3.90326 0.75 4.5C0.75 5.09674 0.987053 5.66903 1.40901 6.09099C1.83097 6.51295 2.40326 6.75 3 6.75ZM9 3C8.17031 3 7.5 3.67031 7.5 4.5C7.5 5.32969 8.17031 6 9 6H22.5C23.3297 6 24 5.32969 24 4.5C24 3.67031 23.3297 3 22.5 3H9ZM9 10.5C8.17031 10.5 7.5 11.1703 7.5 12C7.5 12.8297 8.17031 13.5 9 13.5H22.5C23.3297 13.5 24 12.8297 24 12C24 11.1703 23.3297 10.5 22.5 10.5H9ZM9 18C8.17031 18 7.5 18.6703 7.5 19.5C7.5 20.3297 8.17031 21 9 21H22.5C23.3297 21 24 20.3297 24 19.5C24 18.6703 23.3297 18 22.5 18H9ZM3 21.75C3.59674 21.75 4.16903 21.5129 4.59099 21.091C5.01295 20.669 5.25 20.0967 5.25 19.5C5.25 18.9033 5.01295 18.331 4.59099 17.909C4.16903 17.4871 3.59674 17.25 3 17.25C2.40326 17.25 1.83097 17.4871 1.40901 17.909C0.987053 18.331 0.75 18.9033 0.75 19.5C0.75 20.0967 0.987053 20.669 1.40901 21.091C1.83097 21.5129 2.40326 21.75 3 21.75ZM5.25 12C5.25 11.7045 5.1918 11.4119 5.07873 11.139C4.96566 10.866 4.79992 10.6179 4.59099 10.409C4.38206 10.2001 4.13402 10.0343 3.86104 9.92127C3.58806 9.8082 3.29547 9.75 3 9.75C2.70453 9.75 2.41194 9.8082 2.13896 9.92127C1.86598 10.0343 1.61794 10.2001 1.40901 10.409C1.20008 10.6179 1.03434 10.866 0.921271 11.139C0.808198 11.4119 0.75 11.7045 0.75 12C0.75 12.2955 0.808198 12.5881 0.921271 12.861C1.03434 13.134 1.20008 13.3821 1.40901 13.591C1.61794 13.7999 1.86598 13.9657 2.13896 14.0787C2.41194 14.1918 2.70453 14.25 3 14.25C3.29547 14.25 3.58806 14.1918 3.86104 14.0787C4.13402 13.9657 4.38206 13.7999 4.59099 13.591C4.79992 13.3821 4.96566 13.134 5.07873 12.861C5.1918 12.5881 5.25 12.2955 5.25 12Z"
			fill="currentColor"
		/>
	</svg>
);
