import { httpClient } from '@/api/http';
import { Output, Question } from '../types';
import { fastBrainQuestions } from './mocks';

export const getFastBrainQuestions = async (): Promise<Question[]> => {
	const { data } = await httpClient.get<Question[]>('/flows/fast_brain/questions');
	return data;
};

export const getFastBrainOutput = async (): Promise<Output> => {
	const { data } = await httpClient.get<Output>('/flow/fast_brain/output');
	return data;
};

export const mockGetFastBrainQuestions = async (): Promise<Question[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(fastBrainQuestions), 500);
	});
