import { RouteObject } from 'react-router-dom';
import RiskAssessmentIntro from '@/views/pages/risk-assessment/intro';
import RiskAssessmentOutput from '@/views/pages/risk-assessment/output';
import RiskAssessmentQuestionnaire from '@/views/pages/risk-assessment/questionnaire';

export const riskAssessmentRoutes: RouteObject[] = [
	{
		path: 'risk-assessment/intro',
		element: <RiskAssessmentIntro />,
	},
	{
		path: 'risk-assessment/questionnaire',
		element: <RiskAssessmentQuestionnaire />,
	},
	{
		path: 'risk-assessment/output',
		element: <RiskAssessmentOutput />,
	},
];
