import { FunctionComponent, ReactElement } from 'react';
import { classNames } from '@/lib/utils';
import { SlowBrainTileSelected } from '@/api/tile-groups/types';
import { ArrowRightRegular } from '@/assets/icons/ArrowRightRegular';
import { CheckSolid } from '@/assets/icons/CheckSolid';
import '@/scss/tile.scss';

export interface SlowBrainTileProps {
	tile: SlowBrainTileSelected;
	classes?: string[];
	imgSrc?: string;
	onClick?: (tile: SlowBrainTileSelected) => void;
	isSelected: boolean;
	disabled: boolean;
	isCompleted: boolean;
}

export const SlowBrainTile: FunctionComponent<SlowBrainTileProps> = ({
	tile,
	classes = [],
	imgSrc,
	isSelected,
	isCompleted,
	disabled,
	onClick = () => {},
}): ReactElement => (
	<button
		className={classNames(
			'relative',
			'h-[74px]',
			'w-[145px]',
			'flex',
			'shrink-0',
			'items-center',
			'justify-center',
			'py-3',
			'rounded-md',
			...classes
		)}
		onClick={() => onClick(tile)}
		disabled={disabled}
	>
		{(isSelected || isCompleted) && (
			<div
				className={classNames(
					'absolute',
					'left-[5px]',
					'top-[5px]',
					'h-5',
					'w-5',
					'rounded-full',
					'tile-badge',
					'flex',
					'items-center',
					'justify-center',
					'text-white',
					[isSelected ? ['tile-badge--selected'] : []],
					[isCompleted ? ['tile-badge--completed'] : []]
				)}
			>
				{isSelected && <ArrowRightRegular width={12} height={12} />}
				{isCompleted && <CheckSolid width={12} height={12} />}
			</div>
		)}
		<img src={imgSrc} alt="Tile" className="absolute inset-0 h-full w-full opacity-30" />
		<p className="relative text-center text-button-s">{tile?.text}</p>
	</button>
);
