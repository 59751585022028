import { useSignals } from '@preact/signals-react/runtime';
import { postSelectedTiles } from '@/api/tile-groups';
import { pickSlowBrainTiles } from '@/api/tile-groups/slow-brain';
import { TileSelectedPayload } from '@/api/tile-groups/types';
import {
	slowBrainMoneySelectedTiles,
	slowBrainPersonalSelectedTiles,
	slowBrainTop5SelectedTiles,
	slowBrainVulnerabilitiesSelectedTiles,
} from '@/views/scenes/signals/tiles.signals';
import { SlowBrainTileSelectionStep } from '@/features/slow-brain/types';
import { SlowBrainStepsTileGroups } from './useSlowBrainTileGroups';

interface UseSlowBrainOnNextStepProps {
	currentStep: SlowBrainTileSelectionStep;
	stepTileGroups: SlowBrainStepsTileGroups;
}

interface UseSlowBrainOnNextStep {
	onNextStep: () => Promise<void>;
}

export const useSlowBrainOnNextStep = ({
	currentStep,
	stepTileGroups,
}: UseSlowBrainOnNextStepProps): UseSlowBrainOnNextStep => {
	useSignals();

	const {
		personal_outcomes: personalOutcomesTileGroup,
		money_outcomes: moneyOutcomesTileGroup,
		plan_vulnerabilities: planVulnerabilitiesTileGroup,
	} = stepTileGroups;

	const onPersonalOutcomesSubmit = async (): Promise<void> => {
		const { id: tileGroupId } = personalOutcomesTileGroup!;
		const [{ tiles: selectedTiles }] = slowBrainPersonalSelectedTiles.value;
		const payload: TileSelectedPayload[] = selectedTiles.map(({ tag, slot }) => ({ tag, slot }));
		return postSelectedTiles(tileGroupId, payload);
	};

	const onMoneyOutcomesSubmit = async (): Promise<void> => {
		const { id: tileGroupId } = moneyOutcomesTileGroup!;
		const [{ tiles: selectedTiles }] = slowBrainMoneySelectedTiles.value;
		const payload: TileSelectedPayload[] = selectedTiles.map(({ tag, slot }) => ({ tag, slot }));
		return postSelectedTiles(tileGroupId, payload);
	};

	const onPlanVulnerabilitiesSubmit = async (): Promise<void> => {
		const { id: tileGroupId } = planVulnerabilitiesTileGroup!;
		const [{ tiles: selectedTiles }] = slowBrainVulnerabilitiesSelectedTiles.value;
		const payload: TileSelectedPayload[] = selectedTiles.map(({ tag, slot }) => ({ tag, slot }));
		return postSelectedTiles(tileGroupId, payload);
	};

	const onPickTop5 = async (): Promise<void> => {
		const [{ tiles: selectedTiles }] = slowBrainTop5SelectedTiles.value;
		const payload: TileSelectedPayload[] = selectedTiles.map(({ tag, slot }) => ({ tag, slot }));
		return pickSlowBrainTiles(payload);
	};

	let onNextStep: UseSlowBrainOnNextStep['onNextStep'] = onPickTop5;
	if (currentStep === 'personal_outcomes') onNextStep = onPersonalOutcomesSubmit;
	if (currentStep === 'money_outcomes') onNextStep = onMoneyOutcomesSubmit;
	if (currentStep === 'plan_vulnerabilities') onNextStep = onPlanVulnerabilitiesSubmit;
	if (currentStep === 'top_5_selection') onNextStep = onPickTop5;

	return { onNextStep };
};
