import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import Footer from '@/components/ui/footer';
import PageLayout from '@/views/layouts/page-layout';
import LinkButton from '@/components/ui/link-button';
import { SuccessBadge } from '@/components/feature/badges';
import OutputLoader from '@/components/feature/output-loader';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import { Print } from '@/assets/icons/Print';
import { getRiskAssessmentOutput } from '@/api/questions/risk-assessment';
import '@/scss/_output.scss';

interface RiskAssessmentOutput {
	__html: string;
}

const RiskAssessmentOutput: FunctionComponent = (): ReactElement => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [outputData, setOutputData] = useState<RiskAssessmentOutput>();

	const { mutate } = useMutation({
		mutationFn: () => getRiskAssessmentOutput(),
		onSuccess: (data) => {
			setOutputData({ __html: DOMPurify.sanitize(data.outputText) });
			setIsFinished(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 2500);
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => mutate(), []);

	const printButton: ReactNode = useMemo(
		() =>
			!isLoading ? (
				<ButtonPrimary className="print:hidden" icon={<Print />} onClick={() => window.print()}>
					Save as PDF
				</ButtonPrimary>
			) : undefined,
		[isLoading]
	);

	return (
		<PageLayout
			theme="light"
			footer={<Footer withBoxShadow={false} itemsPosition="start" size="md" />}
			headerControls={printButton}
		>
			{isLoading ? (
				<OutputLoader completed={isFinished} />
			) : (
				<div className="m-auto flex max-w-[872px] flex-col gap-12 p-12">
					<div className="flex flex-col gap-6">
						<div className="flex flex-col gap-6">
							<SuccessBadge />
							<h1 className="text-results-title">Risk Assessment Questionnaire</h1>
						</div>
						<p className="text-body-18-md">
							Thank you for working through your Risk Assessment Questionnaire. Here are the
							recommended next steps to help you implement your investment strategy and stay on
							track with your financial goals:
						</p>
					</div>
					<div
						className="risk-assessment-output flex flex-col gap-6 rounded-md bg-white p-8 shadow print:p-0 print:shadow-none"
						dangerouslySetInnerHTML={outputData}
					></div>
					<div className="inline self-end print:hidden">
						<LinkButton to="../portfolio-recommendations/intro" title="Continue">
							Continue
						</LinkButton>
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default RiskAssessmentOutput;
