import { CheckSolid } from '@/assets/icons/CheckSolid';
import './radio-input.scss';

interface RadioInputProps {
	id?: string;
	name?: string;
	value?: string;
	onChange?: (e: string) => void;
	label?: string;
	withIcon?: boolean;
	checked?: boolean;
}

const RadioInput: React.FC<RadioInputProps> = ({
	id,
	name,
	value,
	onChange,
	label,
	withIcon = true,
	checked,
}) => (
	<div className="radio-input__wrapper">
		<input
			id={id}
			name={name}
			value={value}
			onChange={(e) => onChange?.(e.target.value)}
			type="checkbox"
			className="radio-input"
			checked={checked}
		/>
		<label htmlFor={id} className="radio-input__display">
			{withIcon && (
				<span className="radio-input__display__icon h-6 min-h-6 w-6 min-w-6">
					<CheckSolid width={16} height={16} />
				</span>
			)}
			{label}
		</label>
	</div>
);

export default RadioInput;
