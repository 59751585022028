/* eslint-disable no-param-reassign */
import {
	AbstractMesh,
	AnimationGroup,
	AssetsManager,
	Scene as BabylonScene,
	MeshAssetTask,
	ShadowGenerator,
	Vector3,
	Animation,
	PBRMaterial,
	EasingFunction,
	SineEase,
	Material,
	Color3,
	SpotLight,
	Tools,
	TransformNode,
	FreeCamera,
	BoundingInfo,
	Texture,
} from '@babylonjs/core';
import { AdvancedDynamicTexture, Control, TextBlock } from '@babylonjs/gui';
import {
	CategorizedSelectedTiles,
	CategorizedTiles,
	HandData,
	InternalTileData,
	LoadResult,
	SlotData,
	SlowBrainCategory,
	TileMaterialCategory,
	TileData,
	TileFontColor,
	TileColor,
} from '@/views/scenes/types/tiles.types';
import { TileGroup, TileGroupSelected, TileSelected, TileTag } from '@/api/tile-groups/types';

// *******************************ADJUSTABLE METHODS (light, shadows, text, materials, camera)*********************************************

// setup spotlight that comes from blender file
// adjust intensity and angles if needed
export const setupSpotlight = (scene: BabylonScene) => {
	const spotLight = scene.lights.find((light) =>
		light.getClassName().includes('SpotLight')
	) as SpotLight;
	if (spotLight) {
		spotLight.intensity = 60;
		spotLight.angle = Tools.ToRadians(100);
		spotLight.innerAngle = Tools.ToRadians(70);
	}

	return spotLight;
};

// setup the text and font color of the tile (domino)
export const addTextToTile = (tile: InternalTileData, targetMaterial: string | null) => {
	const adt = AdvancedDynamicTexture.CreateForMesh(tile.textTarget, 512, 512, false, false, false);
	tile.textTarget.isPickable = false;

	// possible color for text depending on the incoming material category.
	// adjust TileFontColor values if needed in tiles.types.ts
	const colorMap: Record<TileMaterialCategory, TileFontColor> = {
		PersonalOutcomes_material: TileFontColor.Personal,
		MoneyOutcomes_material: TileFontColor.Money,
		PlanVulnerabilities_material: TileFontColor.Vulnerabilities,
		fastbrain_material: TileFontColor.FastBrain,
	};
	// default value fallback, black
	let textColor = TileFontColor.Default;
	// assign color depending on incoming material
	if (targetMaterial) {
		Object.keys(colorMap).forEach((key) => {
			if (targetMaterial.includes(key)) {
				const targetKey = key as TileMaterialCategory;
				textColor = colorMap[targetKey];
			}
		});
	}
	// adjust text options
	const textBlock = new TextBlock(tile.textTarget.name, tile.tileText);
	textBlock.fontSize = 65;
	textBlock.fontWeight = '800';
	textBlock.fontFamily = 'Inter, sans-serif';
	textBlock.color = textColor;
	textBlock.textWrapping = true;
	textBlock.isPointerBlocker = false;
	textBlock.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
	textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
	adt.addControl(textBlock);
};

// process scene materials
// adjust as needed
export const processMaterials = (materials: Material[]) => {
	// desk material
	const deskMaterial = materials.find(
		(material) => material.name === 'desk_material'
	) as PBRMaterial;

	const deskMaterialTextures = deskMaterial.getActiveTextures();

	// for each texture in material (base, roughness, bump) set proper tiling
	// depending on the texture quality and targeted effect adjust the textures
	deskMaterialTextures.forEach((deskTexture) => {
		const texture = deskTexture as Texture;
		if (texture) {
			texture.vScale = 3; // v tiling
			texture.uScale = 3; // u tiling
		}
	});
	// rougness of the desk => smaller value = more reflective; higher value = more diffuse
	deskMaterial.roughness = 0.7;
	// set the color of the desk, this is used to make it appear a bit darker relative to initial value from blender
	deskMaterial.albedoColor = Color3.FromHexString('#B5B4B4').toLinearSpace();

	// material for hand mesh
	const handMaterial = materials.find(
		(material) => material.name === 'hand_material'
	) as PBRMaterial;
	if (handMaterial) {
		handMaterial.roughness = 1;
		handMaterial.useRoughnessFromMetallicTextureGreen = true;
	}

	// values used for all domino materials
	// percentage of how much the lighting (spotlight and ambient light) is affecting the domino material (1.0 = 100%, 0.0 = 0%)
	const directIntensityLevel = 0.8;
	// metallic level of the domino material, used to give it a bit of contour and reflectiveness (1.0 = completely metallic)
	const metallicLevel = 0.1;

	// FOR ALL DOMINOS -> Adjust TileColor  values per domino category in tiles.types.ts if adjustment is needed

	// PersonalOutcomes domino material
	const personalMat = materials.find((material) =>
		material.name.includes('PersonalOutcomes_material')
	) as PBRMaterial;
	if (personalMat) {
		personalMat.albedoColor = Color3.FromHexString(TileColor.Personal).toLinearSpace();
		personalMat.metallic = metallicLevel;
		personalMat.directIntensity = directIntensityLevel;
	}

	// MoneyOutcomes domino material
	const moneyMat = materials.find((material) =>
		material.name.includes('MoneyOutcomes_material')
	) as PBRMaterial;
	if (moneyMat) {
		moneyMat.albedoColor = Color3.FromHexString(TileColor.Money).toLinearSpace();
		moneyMat.metallic = metallicLevel;
		moneyMat.directIntensity = directIntensityLevel;
	}

	// PlanVulnerabilities domino material
	const vulnerabilitiesMat = materials.find((material) =>
		material.name.includes('PlanVulnerabilities_material')
	) as PBRMaterial;
	if (vulnerabilitiesMat) {
		vulnerabilitiesMat.albedoColor = Color3.FromHexString(
			TileColor.Vulnerabilities
		).toLinearSpace();
		vulnerabilitiesMat.metallic = metallicLevel;
		vulnerabilitiesMat.directIntensity = directIntensityLevel;
	}

	// FastBrain domino material
	const fastBrainMat = materials.find((material) =>
		material.name.includes('fastbrain_material')
	) as PBRMaterial;
	if (fastBrainMat) {
		fastBrainMat.albedoColor = Color3.FromHexString(TileColor.FastBrain).toLinearSpace();
		fastBrainMat.metallic = metallicLevel;
		fastBrainMat.directIntensity = directIntensityLevel;
	}
};

// used in Top5 scene to set FOV (zoom level) depending on how many dominos are visible in the scene
// checking the nnumber of rows of dominos so it is more zoomed in when there is less dominos in the scene
export const updateCameraFovPerDominos = (
	camera: FreeCamera,
	numDominos: number,
	minFOV: number = 0.32, // smaller possible  fov value, when there is small number of dominos in the scene =>
	maxFOV: number = camera.fov // max fov posssible when max dominos are in the scene
) => {
	const numRows = Math.ceil(Math.min(numDominos, 24) / 4);

	let newFOV: number;

	// 0-4 rows of dominos use the same FOV value
	// 5 rows used unique value, defined during testing, adjust if needed
	// 6 rows use max fov so all dominos are visible
	if (numRows <= 4) {
		newFOV = minFOV;
	} else if (numRows === 5) {
		const midFOV = (minFOV + maxFOV) / 2;
		newFOV = minFOV + 0.4 * (midFOV - minFOV);
	} else {
		newFOV = minFOV + (maxFOV - minFOV);
	}

	camera.fov = newFOV;
};

// used in Top5 scene, similalry to FOV, depending on the number of domino rows, adjust the position of those dominos via proxy parent object
export const updateTemporaryParentZOffset = (
	temporaryParent: AbstractMesh,
	numDominos: number,
	minZOffset: number = 0.8, // the amount that is used when 1 row is present only, so 1 row of dominos is not actually pinned to the top of the view, but offseted to the bottom a bit
	maxZOffset: number = 0 // the amount that is used when max rows are present so there is no offset at all, because all dominos are present and visible
) => {
	const numRows = Math.ceil(Math.min(numDominos, 24) / 4);

	const ratio = (numRows - 1) / (6 - 1);

	const newZOffset = minZOffset + ratio * (maxZOffset - minZOffset);

	temporaryParent.position.z += newZOffset;
};

// create shadow generator for shadows
export const createShadowGenerator = (light: SpotLight): ShadowGenerator => {
	// set the resolution of the shadow map if needed, the more it is, better the shadow quality, but impacts performance
	const shadowGenerator = new ShadowGenerator(2048, light);
	// set how dark the shadow is, 0 => max darkness; 1 => min darkness (no shadow)
	shadowGenerator.darkness = 0;
	shadowGenerator.usePercentageCloserFiltering = true;
	shadowGenerator.bias = 0.0001;
	return shadowGenerator;
};

// *******************************ANIMATION METHODS - ADJUST IF NEEDED********************************************

// nullify quaternion rotations; Needed to use euler rotation values, as defaulted values are in quaternion
export const resetQuaterinionRotation = (tile: InternalTileData) => {
	const initialEulerRotation = tile.mesh.rotationQuaternion?.toEulerAngles();
	tile.mesh.rotationQuaternion = null;
	if (initialEulerRotation) {
		tile.mesh.rotation = initialEulerRotation;
	}
};

// used to simulate camera zoom animation in the scenes
export const createCameraFOVAnimation = (
	camera: FreeCamera,
	endFrame: number,
	scene: BabylonScene
) => {
	const frameRate = 30;

	// fov animation, where the animated value is number (float)
	const fovAnimation = new Animation(
		'fovAnimation',
		'fov',
		frameRate,
		Animation.ANIMATIONTYPE_FLOAT,
		Animation.ANIMATIONLOOPMODE_CONSTANT
	);

	// adjustable values
	// FOV => smaller value means more "zoomed in"

	// offset initial camera fov which comes from the 3D blender file, as it initially is designed to be closer than we wanted
	// this sets the starting fov to look like its more zoomed out than the blender file accounted for
	const cameraOffsetFOV = 0.2;

	// fov at the end of the animation, this is the value where fov will end up after the scene is ready to be interacted with
	const cameraTargetFOV = 0.3; // fov value at the end of the animation

	// keyframes, can add more or remove if needed
	// currently from the frame 0 to frame endFrame/2 (middle of animation duration) fov is the same, and then it stats "zooming in"
	const fovKeys = [
		{
			frame: 0,
			value: camera.fov + cameraOffsetFOV,
		},
		{
			frame: endFrame / 2,
			value: camera.fov + cameraOffsetFOV,
		},
		{
			frame: endFrame,
			value: cameraTargetFOV,
		},
	];

	fovAnimation.setKeys(fovKeys);

	const easingFunction = new SineEase();
	easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

	fovAnimation.setEasingFunction(easingFunction);

	const animationGroup = new AnimationGroup('camera_fov_animation', scene);
	animationGroup.addTargetedAnimation(fovAnimation, camera);

	return animationGroup;
};

// create animation for stacking dominos to slots
export const createStackingAnimation = (
	tile: InternalTileData,
	slotPosition: Vector3,
	scene: BabylonScene
) => {
	const frameRate = 30;
	const positionAnimation = new Animation(
		'positionAnimation',
		'position',
		frameRate,
		Animation.ANIMATIONTYPE_VECTOR3,
		Animation.ANIMATIONLOOPMODE_CONSTANT
	);

	const positionKeys = [
		{
			frame: 0,
			value: tile.mesh.position.clone(),
		},
		{
			frame: frameRate,
			value: slotPosition,
		},
	];

	positionAnimation.setKeys(positionKeys);

	resetQuaterinionRotation(tile);

	const rotationAnimation = new Animation(
		'rotationAnimation',
		'rotation.y',
		frameRate,
		Animation.ANIMATIONTYPE_FLOAT,
		Animation.ANIMATIONLOOPMODE_CONSTANT
	);

	const rotationKeys = [
		{
			frame: 0,
			value: tile.mesh.rotation.clone().y,
		},
		{
			frame: frameRate,
			value: 0,
		},
	];

	rotationAnimation.setKeys(rotationKeys);

	const easingFunction = new SineEase();
	easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

	positionAnimation.setEasingFunction(easingFunction);
	rotationAnimation.setEasingFunction(easingFunction);

	const animationGroup = new AnimationGroup('stackingAnimation', scene);
	animationGroup.addTargetedAnimation(positionAnimation, tile.mesh);
	animationGroup.addTargetedAnimation(rotationAnimation, tile.mesh);

	return animationGroup;
};

// create hover animation for dominos
export const createHoverAnimation = (tile: InternalTileData, scene: BabylonScene) => {
	const frameRate = 30;
	// the level of how much the domino raise up toward the camera; adjustable
	const raiseAmount = 0.2;

	let cameraPosition = new Vector3(0, 0, 0);

	const camera = scene.activeCamera;
	if (camera && camera.parent) {
		const cameraParent = camera.parent as TransformNode;
		cameraPosition = cameraParent.position;
	}
	// calculate direction from domino to camera so it can raise toward the camera
	const directionToCamera = cameraPosition.subtract(tile.mesh.position).normalize();
	// scale the value with raiseAmount
	const targetPosition = tile.mesh.position.add(directionToCamera.scale(raiseAmount));

	const positionAnimation = new Animation(
		'hoverPositionAnimation',
		'position',
		frameRate,
		Animation.ANIMATIONTYPE_VECTOR3,
		Animation.ANIMATIONLOOPMODE_CONSTANT
	);

	const positionKeys = [
		{ frame: 0, value: tile.mesh.position.clone() },
		{ frame: frameRate, value: targetPosition },
	];

	positionAnimation.setKeys(positionKeys);

	resetQuaterinionRotation(tile);

	const rotationAnimation = new Animation(
		'hoverRotationAnimation',
		'rotation.y',
		frameRate,
		Animation.ANIMATIONTYPE_FLOAT,
		Animation.ANIMATIONLOOPMODE_CONSTANT
	);

	const initialRotationY = tile.mesh.rotation.y;
	const rotationKeys = [
		{ frame: 0, value: initialRotationY },
		{ frame: frameRate, value: 0 },
	];

	rotationAnimation.setKeys(rotationKeys);

	const easeInOut = new SineEase();
	easeInOut.setEasingMode(SineEase.EASINGMODE_EASEINOUT);

	positionAnimation.setEasingFunction(easeInOut);
	rotationAnimation.setEasingFunction(easeInOut);

	const animationGroup = new AnimationGroup('hoverAnimation', scene);
	animationGroup.addTargetedAnimation(positionAnimation, tile.mesh);
	animationGroup.addTargetedAnimation(rotationAnimation, tile.mesh);

	return animationGroup;
};

// stacking animation above is created when the tile is clicked
// initially, we don't know to which slot the tile should go, as we don't know which slot is availabale
// each time that tile is stacked and removed and stacked again, we need to update TARGET END POSITION of the tile, represented by position of the slot
// this method updates the end position so animation always know to which position it should animate to depending on the available slot
export const updateStackingAnimationPosition = (
	stackingAnimation: AnimationGroup,
	newSlotPosition: Vector3
) => {
	const positionAnimation = stackingAnimation.targetedAnimations.find(
		(anim) => anim.animation.name === 'positionAnimation'
	)?.animation;

	if (positionAnimation) {
		const newKeys = [
			{
				frame: 0,
				value: positionAnimation.getKeys()[0].value as Vector3, // use existing start position
			},
			{
				frame: 30,
				value: newSlotPosition, // set new end position
			},
		];

		positionAnimation.setKeys(newKeys);
	}
};

// when we are loading the scene where some selections are already made by user previously, we need to force selected dominos to their slot position
// when we force that position, stacking animation start position and rotations are off (as we properly set those upon click on tile, but due forcing there is no initial clicking)
// in that case we need to manually set proper start position and rotation via this method
// we set START position and rotation, because as domino is already stacked in this case, we need to go from END frame to START frame (reversed stacking animationn)
// in this reversed animation case, START position and rotation is acting as END position and rotation, and vice versa
export const updateStackingAnimationStartPositionAndRotation = (
	stackingAnimation: AnimationGroup,
	newStartPosition: Vector3,
	newStartRotation: number
) => {
	// Update the start position
	const positionAnimation = stackingAnimation.targetedAnimations.find(
		(anim) => anim.animation.name === 'positionAnimation'
	)?.animation;

	if (positionAnimation) {
		const currentKeys = positionAnimation.getKeys();
		if (currentKeys.length > 0) {
			const newKeys = [
				{
					frame: currentKeys[0].frame,
					value: newStartPosition, // new start position
				},
				{
					frame: currentKeys[currentKeys.length - 1].frame,
					value: currentKeys[currentKeys.length - 1].value as Vector3, // existing end position
				},
			];

			positionAnimation.setKeys(newKeys);
		}
	}

	// Update the start rotation
	const rotationAnimation = stackingAnimation.targetedAnimations.find(
		(anim) => anim.animation.name === 'rotationAnimation'
	)?.animation;

	if (rotationAnimation) {
		const currentKeys = rotationAnimation.getKeys();
		if (currentKeys.length > 0) {
			const newKeys = [
				{
					frame: currentKeys[0].frame,
					value: newStartRotation, // new start rotation
				},
				{
					frame: currentKeys[currentKeys.length - 1].frame,
					value: currentKeys[currentKeys.length - 1].value as number, // existing end rotation
				},
			];

			rotationAnimation.setKeys(newKeys);
		}
	}
};

// *******************************HELPER METHODS, NO ADJUSTMENT NEEDED************************************************

// gets the random data from the input TileGroup (text, tag, id)
// note that it use .splice method which mutates initial array by completely removing the data it randomly chose..
// ...for the purpose of disabling the possibility of repeating the domino with the same data
const getRandomTile = (array: TileData[]) => {
	const randomIndex = Math.floor(Math.random() * array.length);
	const selectedTile = array[randomIndex];
	array.splice(randomIndex, 1);
	return selectedTile;
};

// one of the core methods in the app. Used for loading the 3D file and processing the information within the file
// includes processing and resolving data for Tiles (dominos), Slots and Hand data
export const loadAndProcessScene = async (
	sceneUrl: string,
	tilesData: TileData[],
	selectedTiles: TileSelected[],
	scene: BabylonScene
) => {
	const assetsManager = new AssetsManager(scene);
	assetsManager.useDefaultLoadingScreen = false;

	// check if mesh name is matching domino name pattern (regex)
	const tileNamePattern = /^domino_\d+$/;

	const selectedTileDataMap = new Map<string, TileData>();
	const tilesSearchArray: TileData[] = [...tilesData];
	const tileCount = tilesSearchArray.length;

	// remove the data from the search array and store it in map for purpose of separating randomness from specific selected data
	// used to restore selected tiles data to not include random text assignment
	selectedTiles.forEach((selectedTile) => {
		const tileDataIndex = tilesSearchArray.findIndex((tile) => tile.tag === selectedTile.tag);
		if (tileDataIndex !== -1) {
			const tileData = tilesSearchArray[tileDataIndex];
			selectedTileDataMap.set(selectedTile.slot.targetTile, tileData);
			tilesSearchArray.splice(tileDataIndex, 1);
		}
	});

	return new Promise<LoadResult<InternalTileData>>((resolve, reject) => {
		// assets manager is used to load external assets, and it is doing that via tasks
		// each task represents loading a single file, in this case that's the GLB 3D object
		const loadTask = assetsManager.addMeshTask('tilescene', '', sceneUrl, '');

		// success callback
		loadTask.onSuccess = (task: MeshAssetTask) => {
			const tiles: InternalTileData[] = [];
			const root = task.loadedMeshes[0];
			const slots: SlotData[] = [];
			const meshes = root.getChildMeshes();
			const animationGroups = task.loadedAnimationGroups;
			const transformNodes = task.loadedTransformNodes;

			// handle hand assets and set initial data state
			const handMesh = meshes.filter((mesh) => mesh?.name === 'hand')[0];
			let handData: HandData | null = null;
			if (handMesh) {
				const handAnimation = animationGroups.find((animation) => animation.name.includes('hand'));
				handData = {
					name: handMesh.name,
					mesh: handMesh,
					animation: handAnimation || null,
				};
			}

			// handle tile assets and set initial data set
			meshes.forEach((mesh) => {
				// enable shadow recieving for all meshes in the scene except textTarget planes
				if (!mesh.name.includes('textTarget')) {
					mesh.receiveShadows = true;
				}

				const dropAnimation = animationGroups.find(
					(animation) => animation.name === `${mesh.name}_dropAnimation`
				);
				if (tileNamePattern.test(mesh.name)) {
					const tileNumber = +mesh.name.split('_')[1];

					if (tileNumber <= tileCount) {
						const textTarget = mesh.getChildMeshes()[0];
						textTarget.isPickable = false;

						// get data for tile, either random for unselected tiles, or specific data for previously selected tiles
						const tileData = selectedTileDataMap.has(mesh.name)
							? selectedTileDataMap.get(mesh.name)!
							: getRandomTile(tilesSearchArray);

						const initialTileData: InternalTileData = {
							name: mesh.name,
							mesh,
							dropAnimation: dropAnimation || null,
							stackingAnimation: null,
							hoverAnimation: null,
							isSelected: selectedTileDataMap.has(mesh.name),
							textTarget,
							tileTag: tileData?.tag || 'placeholder_text',
							tileText: tileData?.text || 'Placeholder Text',
							initialPosition: [mesh.position.x, mesh.position.y, mesh.position.z],
						};

						tiles.push(initialTileData);
					} else {
						// for the purpose of the Top5 scene, where 3D file itself actually contains 24 dominos, and we might not need all of them..
						// ..if the number of incoming tiles is less than 24, completely remove (dispose), rest of the dominos which are not needed
						// this is the reason why there is no tiles in the scenes if empty array for TilesGroup is provided to the component
						mesh.dispose();
					}
				}
			});

			// handle slots assets and set initial slot data
			transformNodes.forEach((node) => {
				if (node.name.includes('slotLocation')) {
					const position = node.position.clone();
					const id = parseInt(node.name.split('_')[1], 10);
					const initialSlotData: SlotData = {
						id,
						isOccupied: false,
						tile: null,
						position: [position.x, position.y, position.z],
					};

					slots.push(initialSlotData);
				}
			});
			// sort slots properly
			slots.sort((a, b) => a.id - b.id);

			resolve({ tiles, slots, handData });
		};

		loadTask.onError = (_task, message, exception) => {
			const error = exception as Error;
			reject(new Error(`Failed to load meshes: ${message} ${error ? error.message : ''}`));
		};

		assetsManager.load();
	});
};

// used in Top5 scene for categorizing the tiles depending on which slow brain flow they are selected from
export const categorizeTiles = (groups: TileGroup[]): CategorizedTiles[] => {
	const categorizedTiles: CategorizedTiles[] = [];
	groups.forEach((group) => {
		const tileWithCategory: CategorizedTiles = {
			tiles: group.tiles,
			category: group.category as SlowBrainCategory,
		};
		categorizedTiles.push(tileWithCategory);
	});

	return categorizedTiles;
};

// used in Top5 scene for categorizing the selectedTiles depending on which slow brain flow they are selected from
export const categorizeSelectedTiles = (
	groups: TileGroupSelected[]
): CategorizedSelectedTiles[] => {
	const categorizedTiles: CategorizedSelectedTiles[] = [];
	groups.forEach((group) => {
		const tileWithCategory: CategorizedSelectedTiles = {
			tiles: group.tiles,
			category: group.category as SlowBrainCategory,
		};
		categorizedTiles.push(tileWithCategory);
	});

	return categorizedTiles;
};

// get tile category
export const findCategoryForTile = (
	tileTag: TileTag,
	categorizedTilesArray: CategorizedTiles[]
): SlowBrainCategory | null => {
	const categorizedTile = categorizedTilesArray.find((categorizedTile) =>
		categorizedTile.tiles.some((tile) => tile.tag === tileTag)
	);

	return categorizedTile ? categorizedTile.category : null;
};

// get selected tile category
export const findCategoryForSelectedTile = (
	tileTag: TileTag,
	categorizedTilesArray: CategorizedSelectedTiles[]
): SlowBrainCategory | null => {
	const categorizedTile = categorizedTilesArray.find((categorizedTile) =>
		categorizedTile.tiles.some((tile) => tile.tag === tileTag)
	);

	return categorizedTile ? categorizedTile.category : null;
};

// used in Top5 scene to set the proper material to tiles depending on the category those are selected from
export const setMaterialsPerCategory = (
	category: string,
	tile: InternalTileData,
	scene: BabylonScene
) => {
	let materialName = '';
	let materialSuffix = '';

	switch (category) {
		case 'Personal':
			materialName = 'domino_PersonalOutcomes_material';
			materialSuffix = 'PersonalOutcomes_material';
			break;
		case 'Money':
			materialName = 'domino_MoneyOutcomes_material';
			materialSuffix = 'MoneyOutcomes_material';
			break;
		case 'Vulnerabilities':
			materialName = 'domino_PlanVulnerabilities_material';
			materialSuffix = 'PlanVulnerabilities_material';
			break;
		default:
			return;
	}

	const material = scene
		.getMaterialByName(materialName)
		?.clone(`${tile.name}_${materialSuffix}`) as PBRMaterial;
	if (material) {
		tile.mesh.material = material;
	}
};

// used in Top5 scene, calculate total bounding of the tiles in the scene so position offset of the tiles can be calculated
// used in conjuction with  updateTemporaryParentZOffset method
export const totalBoundingInfo = (tiles: InternalTileData[]) => {
	let boundingInfo = tiles[0].mesh.getBoundingInfo();

	let min = boundingInfo.boundingBox.minimumWorld;
	let max = boundingInfo.boundingBox.maximumWorld;

	for (let i = 1; i < tiles.length; i += 1) {
		boundingInfo = tiles[i].mesh.getBoundingInfo();

		min = Vector3.Minimize(min, boundingInfo.boundingBox.minimumWorld);
		max = Vector3.Maximize(max, boundingInfo.boundingBox.maximumWorld);
	}

	return new BoundingInfo(min, max);
};

export const playAnimation = (animation: AnimationGroup) => {
	if (!animation) return;

	if (animation.isPlaying) {
		animation.stop();
	}
	animation.start(false, 1.0, animation.from, animation.to, false);
};

// when not initial load force animations to end frame
export const forceEndFrameAnimation = (animation: AnimationGroup) => {
	if (animation.isPlaying) {
		animation.stop();
	}
	animation.goToFrame(animation.to);
	animation.start(false, 1.0, animation.to, animation.to, false);
};

// find first slot in the scene that is not occupied
export const getFirstAvailableSlot = (slots: SlotData[]) => slots.find((slot) => !slot.isOccupied);

export const showInspector = (scene: BabylonScene) => {
	setTimeout(() => {
		scene.debugLayer.show().catch((error) => {
			console.error('Failed to show inspector: ', error);
		});
	}, 0);
};
