import { useQueries, useQuery } from '@tanstack/react-query';
import { getRiskAssessmentQuestions } from '@/api/questions/risk-assessment';
import { getRiskAssessmentAnswers } from '@/api/answers/risk-assessment';

export const riskAssessmentKeys = {
	questions: ['risk_assessment_questions'],
	answers: ['risk_assessment_answers'],
};

export const useRiskAssessmentQuestionsOptions = () => ({
	queryKey: riskAssessmentKeys.questions,
	queryFn: () => getRiskAssessmentQuestions(),
});

export const useRiskAssessmentQuestions = () => useQuery(useRiskAssessmentQuestionsOptions());

export const useRiskAssessmentAnswersOptions = () => ({
	queryKey: riskAssessmentKeys.answers,
	queryFn: () => getRiskAssessmentAnswers(),
});

export const useRiskAssessmentAnswers = () => useQuery(useRiskAssessmentAnswersOptions());

export const useRiskAssessmentQuestionsAndAnswers = () =>
	useQueries({
		queries: [
			{ ...useRiskAssessmentQuestionsOptions(), initialData: [] },
			{ ...useRiskAssessmentAnswersOptions(), initialData: [] },
		],
	});
