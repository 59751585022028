import { FunctionComponent, ReactElement } from 'react';
import { CheckSolid } from '@/assets/icons/CheckSolid';
import BadgeLayout from '../BadgeLayout';

const SuccessBadge: FunctionComponent = (): ReactElement => (
	<BadgeLayout>
		<CheckSolid />
	</BadgeLayout>
);

export default SuccessBadge;
