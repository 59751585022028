import { forwardRef, KeyboardEvent, ReactElement } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import './text-input.scss';

interface TextInputProps {
	id?: string;
	name?: string;
	value?: string;
	onChange?: (e: string) => void;
	onPressEnter?: () => void;
	placeholder?: string;
	showInfo?: boolean;
}

const TextInput = forwardRef<HTMLTextAreaElement, TextInputProps>(
	(
		{ id, name, value, onChange, onPressEnter, placeholder, showInfo = true },
		ref
	): ReactElement => {
		const onEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				onPressEnter?.();
			}
		};

		return (
			<div className="text-input__wrapper">
				<ReactTextareaAutosize
					id={id}
					name={name}
					value={value}
					onChange={(e) => onChange?.(e.target.value)}
					placeholder={placeholder}
					className="text-input"
					onKeyDown={(e) => onEnter(e)}
					ref={ref}
				/>
				{showInfo && (
					<small className="text-input__info">
						<strong>Shift &#8679; + Enter &crarr;</strong> to make a line break
					</small>
				)}
			</div>
		);
	}
);

TextInput.displayName = 'Text Input';

export default TextInput;
