import { FunctionComponent, ReactElement } from 'react';
import { ChevronUpSolid } from '@/assets/icons/ChevronUpSolid';
import { ChevronDownSolid } from '@/assets/icons/ChevronDownSolid';
import './button-prev-next.scss';

interface ButtonPrevNextProps {
	onPrevClick: () => void;
	onNextClick: () => void;
	prevDisabled?: boolean;
	nextDisabled?: boolean;
}

const ButtonPrevNext: FunctionComponent<ButtonPrevNextProps> = ({
	onPrevClick,
	onNextClick,
	prevDisabled,
	nextDisabled,
}): ReactElement => (
	<div className="button-prev-next-wrapper">
		<button className="button-prev-next__prev" onClick={onPrevClick} disabled={prevDisabled}>
			<ChevronUpSolid width={18} height={18} />
		</button>
		<button className="button-prev-next__next" onClick={onNextClick} disabled={nextDisabled}>
			<ChevronDownSolid width={18} height={18} />
		</button>
	</div>
);

export default ButtonPrevNext;
