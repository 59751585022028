import { ButtonHTMLAttributes, ReactNode } from 'react';
import './button-primary.scss';
import { classNames } from '@/lib/utils';

export interface ButtonPrimaryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary';
	size?: 'lg' | 'md' | 'sm';
	onClick?: () => void;
	disabled?: boolean;
	icon?: ReactNode;
	children?: ReactNode;
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
	variant = 'primary',
	size = 'lg',
	onClick,
	disabled,
	icon,
	children,
	className,
	...rest
}) => (
	<button
		className={classNames(
			`button button--${variant}`,
			`button--${size}`,
			...(className ? className.split(' ') : [])
		)}
		onClick={onClick}
		disabled={disabled}
		{...rest}
	>
		{children} {icon}
	</button>
);

export default ButtonPrimary;
