import { ReactNode } from 'react';
import { ArrowRightSolid } from '@/assets/icons/ArrowRightSolid';
import ButtonPrimary, { ButtonPrimaryProps } from '../button-primary/ButtonPrimary';

interface ButtonNextProps extends Omit<ButtonPrimaryProps, 'icon'> {
	showIcon?: boolean;
	children?: ReactNode;
}

const ButtonNext: React.FC<ButtonNextProps> = ({ showIcon = true, children = 'Next', ...rest }) => (
	<ButtonPrimary {...rest}>
		{children} {showIcon && <ArrowRightSolid />}
	</ButtonPrimary>
);

export default ButtonNext;
