import { FunctionComponent, ReactElement } from 'react';
import { UserSolid } from '@/assets/icons/UserSolid';
import BadgeLayout from '../BadgeLayout';

const UserBadge: FunctionComponent = (): ReactElement => (
	<BadgeLayout>
		<UserSolid />
	</BadgeLayout>
);

export default UserBadge;
