import { FunctionComponent, ReactElement } from 'react';
import LoadingSpinner from '@/components/ui/loading-spinner';

interface OutputLoaderProps {
	completed?: boolean;
}

const OutputLoader: FunctionComponent<OutputLoaderProps> = ({ completed }): ReactElement => (
	<div className="m-auto flex max-w-[872px] flex-col gap-6 px-6 py-[96px]">
		<LoadingSpinner completed={completed} />
		<p className="text-intro-script">Analyzing...</p>
		<p className="text-body-18-md">
			{`We're diving deep into the data to provide you with personalized and precise results. Your report will be ready soon!`}
		</p>
		<p className="text-body-18-md">Did you know?</p>
		<p className="text-body-18-md">
			{`- Daniel Kahneman, the author of Thinking, Fast and Slow, won the 2002 Nobel Memorial Prize in
			Economic Sciences for his work on behavioral finance and hedonic psychology. Kahneman's book
			explores how the human mind operates through two systems of thought: fast, intuitive thinking,
			and slow, rational thinking.`}
		</p>
	</div>
);

export default OutputLoader;
