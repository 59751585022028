import { QuestionType } from '@/api/questions/types';
import { Answer } from '../types';

export const fastBrainAnswers: Answer[] = [
	{
		question: {
			id: '550e8400-e29b-41d4-a716-446655440000',
			type: QuestionType.OPEN,
			flow: 'fast_brain',
			category: '',
			tag: '',
			tile: '',
			choices: [],
			orderNo: 1,
			questionMetadata: [
				{
					label: '',
					text: 'On the tiles you’ve chosen, can you identify a specific instance in your life where you can trace where this “rule of thumb” came from?',
					subtext: '',
					hint: '',
					imageUrl: null,
				},
			],
		},
		answer: {
			answerText: 'Lorem ipsum dolor.',
			answerIds: null,
		},
	},
];
