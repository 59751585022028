import { useSignals } from '@preact/signals-react/runtime';
import { useEffect } from 'react';
import { isSceneLoading } from '@/views/scenes/signals/tiles.signals';

export const useFastBrainTileSelectionLoader = () => {
	useSignals();
	useEffect(() => {
		if (!isSceneLoading.value) {
			isSceneLoading.value = true;
		}
	}, []);

	return isSceneLoading.value;
};
