import { FunctionComponent, ReactElement } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CLIENT_ID_KEY } from '@/lib/utils';

const AuthLayer: FunctionComponent = (): ReactElement => {
	const { clientId } = useParams();
	if (clientId) localStorage.setItem(CLIENT_ID_KEY as string, clientId);

	return (
		<>
			<Outlet />
		</>
	);
};

export default AuthLayer;
