import { Question, QuestionType } from '../types';

export const fastBrainQuestions: Question[] = [
	{
		id: '10f122bd-f485-4a3d-809d-7c59afb3129a',
		flow: 'fast_brain',
		category: '',
		tile: null,
		tag: null,
		type: QuestionType.OPEN,
		choices: [],
		orderNo: 1,
		questionMetadata: [
			{
				label: '',
				text: 'On the tiles you’ve chosen, can you identify a specific instance in your life where you can trace where this “rule of thumb” came from?',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
	{
		id: '2d51c094-391e-497c-8c93-5a834195529b',
		flow: 'fast_brain',
		category: '',
		tile: null,
		tag: null,
		type: QuestionType.OPEN,
		choices: [],
		orderNo: 2,
		questionMetadata: [
			{
				label: '',
				text: 'Can you identify specific decisions you’ve made where you’ve used one of these “rules of thumb” as your guiding principle?',
				subtext: '',
				hint: '',
				imageUrl: '',
			},
		],
	},
];
