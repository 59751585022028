import { FunctionComponent, ReactElement } from 'react';
import IntroLayout from '@/views/layouts/intro-layout';

const SlowBrainTileQuestionsIntro: FunctionComponent = (): ReactElement => (
	<IntroLayout
		label="Tile Questions"
		heading="Slow Brain"
		link={{ to: '../slow-brain/tile-questions', text: 'Start Tile Questions' }}
	>
		For each of your top 5 tiles, take a moment to carefully answer the provided questions. This
		will help us understand your preferences and tailor the experience to better suit your needs.
	</IntroLayout>
);

export default SlowBrainTileQuestionsIntro;
